import React, { Component } from "react";
import Mobile from "../../core-components/layouts/Mobile";
import { Container } from "reactstrap";
import { withThreeSkyeGlobal } from "@threeskye/global";
import MobileMenu from "./MobileMenu";
import BackToTopButton from "./BackToTopButton";

class PortalContentWrapper extends Component {
	ref = React.createRef();

	componentDidMount() {
		this.props.storage.put("content-wrapper-ref", this.ref);
	}

	render() {
		const { children, hideBackToTop, thirdPartyView, subcontentIdName } = this.props;
		return (
			<div id="portal-content" ref={this.ref}>
				<Mobile>
					<MobileMenu thirdPartyView={thirdPartyView} />
				</Mobile>
				<Container className="px-0">{children}</Container>
				<BackToTopButton showButton={!hideBackToTop} subcontentIdName={subcontentIdName} />
			</div>
		);
	}
}

export default withThreeSkyeGlobal(PortalContentWrapper);
