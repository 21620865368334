import React, { Component } from "react";
import { withThreeSkyeGlobal } from "@threeskye/global";
import VerticalSpacer from "./VerticalSpacer";
import Input from "../inputs/Input";
import Button from "../inputs/Button";
import ErrorMessage from "./ErrorMessage";
import "./ConfirmCode.scss";
import FormErrorMessage from "./FormErrorMessage";

class ConfirmCode extends Component {
	constructor(props) {
		super(props);
		this.state = { code: "", errorMessage: "", serverError: false };
		this.submit = this.submit.bind(this);
	}

	submit() {
		const { respKey, username, onConfirm, storage, remote, source } = this.props;
		const { code } = this.state;
		const data = { email: username, code, key: respKey,  source: source};
		remote.post("/um/validate-code", data).then(
			resp => {
				if (resp.valid === "VALID") {
					//Show password reset page but store key and code first eg:
					storage.put("password_change_key", respKey);
					storage.put("password_change_code", code);
					storage.put("username", username);
					onConfirm(code);
				} else if (resp.valid === "INVALID") this.setState({ errorMessage: "You have entered the wrong code." })
				else if (resp.valid === "account-locked") this.setState({ errorMessage: "This account is locked, please contact your Hobson Wealth adviser." });
			},
			x => {
				//error
				if (x === 400) this.setState({ errorMessage: "You have entered the wrong code." });
				else this.setState({ serverError: true });
			},
		);
	}

	render() {
		let { type, onCancel } = this.props;
		const { code, errorMessage, serverError } = this.state;
		if (serverError) return <ErrorMessage colour="white">There was an error in our system. Please try again later.</ErrorMessage>;
		else {
			type = type || "email";
			return (
				<div className="confirm-code-page">
					{type === "email" ? <p>
						If your email is associated with a Hobson Wealth account, we have sent you a code.  Please enter it below.
					</p>:<p>
						We have sent a code to your {type}. Please enter it below.
					</p>}
					<VerticalSpacer size="14px" />
					<Input
						type="code"
						name="code"
						label={`${type} Code`}
						placeholder="Enter code here..."
						value={code}
						onChange={code => this.setState({ code, errorMessage: "" })}
						// errorMessage={errorMessage}
						onKeyPress={e => e.key === "Enter" && this.submit()}
						autoFocus={true}
						autoComplete="one-time-code" 
					/>
					{errorMessage && <FormErrorMessage><div>{errorMessage}</div></FormErrorMessage>}
					<VerticalSpacer size="14px" />
					<div className="buttons">
						<Button disabled={!code} onClick={this.submit}>
							Submit code
						</Button>
						{onCancel && <Button onClick={onCancel}>CANCEL</Button>}
					</div>
				</div>
			);
		}
	}
}

export default withThreeSkyeGlobal(ConfirmCode);
