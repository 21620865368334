import React, { Component } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import "./MobileMenu.scss";
import VerticalSpacer from "../../core-components/layouts/VerticalSpacer";
import NavigateNext from "@material-ui/icons/NavigateNext";
import Button from "../../core-components/inputs/Button";
import withRouteChange from "@threeskye/route-change";
import LinkItem from "./LinkItem";
import { getPortalAccType, PortalAccTypes, withAccountInfo } from "../../core-components/contexts/AccountsContext";
import { Collapse } from "reactstrap";
import { AssignmentInd, ExpandMore, Group } from "@material-ui/icons";
import { withMobileMenu } from "../../core-components/contexts/MobileMenuContext";
import { LINK_ITEMS, getRoute } from "./CustomerPortalPage";


const thirdPartyCheck = (accounts, user) => {
	if (!accounts) {
		return [];
	}
	return accounts.filter(a => a.named.some(n => n.id === user.id));
}

const MobileMenu = withRouteChange(withAccountInfo(
	class extends Component {
		
		render() {
			const { menuIsOpen, logout, accounts, thirdPartyView, allAccounts, user } = this.props;
			const hasThirdPartyAccess = thirdPartyCheck(allAccounts, user).length > 0
			const hasPortfolioAcc = !!accounts.find((account) => getPortalAccType(account) === PortalAccTypes.PORTFOLIO);
			const switchToThirdParty = () => { this.props.changeRoute('/third-party' + LINK_ITEMS().THIRDPARTYACCESS.path) };
			const switchToAccountAccess = (account) => { 
			   let firstFullAccessAccount = allAccounts.find(a => a.holders.some(h => h.id === user.id))
			   this.props.changeRoute(`/${firstFullAccessAccount.number}`+ LINK_ITEMS().DASHBOARD.path) 

		   };

			return (
				<div id="portal-mobile-menu-links" className={`portal-mobile-menu-links--${menuIsOpen ? "open" : "closed"}`}>
					{ Object.keys(LINK_ITEMS()).map((key,idx)=>{
						const link = LINK_ITEMS()[key];
						const onlyPortfolio = !!link.onlyPortfolio;
						if (onlyPortfolio && !hasPortfolioAcc) 
							return null;
						
						if (thirdPartyView && !link.thirdParty) 
						return null;
						
						if (!thirdPartyView && link.thirdPartyOnly) 
						return null;

						if (link.subPages) {
							return <MobileMenuSublinks key={idx} linkItem={link}>
								{Object.keys(link.subPages)
									.map(subKey=>link.subPages[subKey])
									.map((subItem, idx2)=>{
										if (thirdPartyView && !subItem.thirdParty) return null
										else return <MobileMenuSublink key={idx2} linkItem={link} subLinkItem={subItem} thirdPartyView={hasThirdPartyAccess} />
									})}
							</MobileMenuSublinks>
						} else {
							return <MobileMenuLink key={idx} linkItem={link} thirdPartyView={hasThirdPartyAccess} />;
						}
					})}
					
					<VerticalSpacer size="22.5px" />
					{ hasThirdPartyAccess && (
						thirdPartyView 
							? <Button onClick={() => switchToAccountAccess()} className="mb-2" secondary><Group />Accounts View</Button>
							: <Button onClick={() => switchToThirdParty()} className="mb-2" secondary><AssignmentInd />Third Party View</Button>
					)}
					<Button onClick={logout}>log out</Button>
				</div>
			);
		}
	}
));

const MobileMenuLink = withMobileMenu(
	withAccountInfo(
		class extends Component {
			render() {
				const { linkItem, account, closeMenu, thirdPartyView } = this.props;
				return (
					<NavLink activeClassName="active-link" to={getRoute(account, linkItem, false, thirdPartyView)} exact onClick={closeMenu}>
						<LinkItem linkItem={linkItem} />
						<NavigateNext className="menu-item-arrow-icon" />
					</NavLink>
				);
			}
		}
	)
);

const MobileMenuSublinks = withAccountInfo(
	withRouteChange(
		class extends Component {
			state = { isOpen: this.props.location.pathname.includes(this.props.linkItem.path) };

			componentDidUpdate(prevProps) {
				if (prevProps.isMenuOpen !== this.props.isMenuOpen) {
					this.setState({ isOpen: this.props.location.includes(this.props.linkItem.path) });
				}
			}

			render() {
				const { linkItem, children } = this.props;
				const { isOpen } = this.state;
				return (
					<div className="sublinks-wrapper">
						<div className="sublinks-header" onClick={() => this.setState((prevState) => ({ isOpen: !prevState.isOpen }))}>
							<LinkItem linkItem={linkItem} />
							<ExpandMore className={`menu-item-arrow-icon${isOpen ? " expanded" : ""}`} />
						</div>
						<Collapse isOpen={isOpen}>{children}</Collapse>
					</div>
				);
			}
		}
	)
);

const MobileMenuSublink = withMobileMenu(
	withAccountInfo(
		class extends Component {
			render() {
				const { linkItem, subLinkItem, account, closeMenu, thirdPartyView } = this.props;
				return (
					<NavLink title={getRoute(account, linkItem, subLinkItem)} to={getRoute(account, linkItem, subLinkItem, thirdPartyView)} className="sub-link" exact activeClassName="active-link" onClick={closeMenu}>
						{subLinkItem.text}
						<NavigateNext className="menu-item-arrow-icon" />
					</NavLink>
				);
			}
		}
	)
);

MobileMenu.propTypes = {
	linkItems: PropTypes.arrayOf(PropTypes.object).isRequired,
	logout: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
	close: PropTypes.func.isRequired,
};

export default withAccountInfo(withMobileMenu(MobileMenu));
