import React, { Component, useEffect, useState } from "react";
import "./CustomerPortalPage.scss";
import "./ChartStyleOverrides.scss";
import withRouteChange from "@threeskye/route-change";
import { Switch, Route, Redirect } from "react-router-dom";
import { Home, BusinessCenter, Assessment, CompareArrows, EventNote, Description, Build, Mail, AssignmentInd } from "@material-ui/icons";
import Portfolio from "./subpages/Portfolio/Portfolio";
import TransactionHistory from "./subpages/TransactionHistory/TransactionHistory";
import MyDocuments from "./subpages/MyDocuments/MyDocuments";
import MyDetails from "./subpages/MyDetails/MyDetails";
import ContactUs from "./subpages/ContactUs/ContactUs";
import StandingOrders from "./subpages/StandingOrders/StandingOrders";
import OtherReports from "./subpages/Analytics/OtherReports";
import FixedInterest from "./subpages/Analytics/FixedInterest";
import PersonalInformation from "./subpages/MyDetails/PersonalInformation";
import AccountInformation from "./subpages/MyDetails/AccountInformation";
import CommonFunctions from "../../core-components/functions/CommonFunctions";
import LoadingOverlay from "../../core-components/layouts/LoadingOverlay";
import Dashboard from "./subpages/Dashboard/Dashboard";
import { AccountInfo, getPortalAccType } from "../../core-components/contexts/AccountsContext";
import { NotificationsWrapper } from "../../core-components/notifications/withNotifications";
import { FiltersWrapper } from "../../core-components/filters-and-toggles/withFilters";
import Desktop from "../../core-components/layouts/Desktop";
import SideNav from "./SideNav";
import { MobileMenuWrapper } from "../../core-components/contexts/MobileMenuContext";
import ReviewDocsPage from "./subpages/Reviews/ReviewDocsPage";
import ReviewViewPage from "./subpages/Reviews/ReviewViewPage";
import ThirdPartyAccess from "./subpages/ThirdPartyAccess/ThirdPartyAccess";
import LoginViewSelect from "../LoggedOutPage/subpages/LoginViewSelect";
import AlertBanner from "../../core-components/misc/AlertBanner";

/* portfolio income removed for SUP-1119
 * 		PORTFOLIO_INCOME: { text: "Portfolio Income", path: "/portfolio-income", component: PortfolioIncome },
 */

export const LINK_ITEMS = (notifications, accountNumber) => {
	
	const newReviewNotification = notifications && notifications.length > 0 && notifications.find((ntf) => {
		const draft = JSON.parse(ntf.content)
		const accNumber = draft && draft.clientPortalLink.split("/")[1]
		if (accNumber === accountNumber && !ntf.clientRead) return true
		else return null
	}) 
	
	return {
	DASHBOARD: { text: "Dashboard", icon: Home, path: "/dashboard", component: Dashboard, excludeFromSideNav: true },
	PORTFOLIO: { text: "Portfolio", icon: BusinessCenter, path: "/portfolio", component: Portfolio },
	ANALYTICS: {
		text: "Analytics", icon: Assessment, path: "/analytics", onlyPortfolio: true,
		subPages: {
			FIXED_INTEREST: { text: "Fixed Interest", path: "", component: FixedInterest },
			/* PORTFOLIO_INCOME Removed for SUP-1119 */
			OTHER_REPORTS: { text: "Other Reports", path: "/other-reports", component: OtherReports },
		},
	},
	TRANSACTIONS: { text: "Transactions", icon: CompareArrows, path: "/transaction-history", component: TransactionHistory },
	STANDING_ORDERS: { text: "Payments", icon: EventNote, path: "/standing-orders", component: StandingOrders },
	DOCUMENTS: {
		text: "Documents",
		icon: Description,
		infoIcon: newReviewNotification ? "New Review Available" : null,
		path: "/my-documents",
		subPages: {
			GENERAL: { text: "General", path: "", component: MyDocuments },
			REVIEWS: { text: "Reviews", path: "/reviews", component: ReviewDocsPage, infoIcon: newReviewNotification ? "New Review Available" : null },
			REVIEW: { text: "Review", path: "/review/:id", component: ReviewViewPage, hide: true },
		},
	},
	// DOCUMENTS: { text: "My Documents", icon: Description, path: "/my-documents", component: MyDocuments },
	THIRDPARTYACCESS: { thirdParty: true, thirdPartyOnly: true, text: "Third Party Access", icon: AssignmentInd, path: "/third-party-access", component: ThirdPartyAccess },
	SETTINGS: {
		thirdParty: true, text: "Settings", icon: Build, path: "/settings",
		subPages: {
			SECURITY: { thirdParty: true, text: "Security", path: "", component: MyDetails },
			PERSONAL_INFORMATION: { text: "Personal Information", path: "/personal-information", component: PersonalInformation },
			ACCOUNT_INFORMATION: { text: "Account Information", path: "/account-information", component: AccountInformation },
		},
	},
	CONTACT_US: { thirdParty: true, text: "Contact Us", icon: Mail, path: "/contact-us", component: ContactUs }
}};

const BASE_LINK = "/:accountNumber";

export const getRoute = (account, linkItem, subLinkItem, thirdPartyView) => {
	if (thirdPartyView) {
		account = { number: 'third-party' }
	}
	if (subLinkItem) {
		return "/" + account.number + linkItem.path + subLinkItem.path;
	}
	return "/" + account.number + linkItem.path;
}

const thirdPartyCheck = (accounts, user) => {
	if (!accounts) {
		return [];
	}
	return accounts.filter(a => a.named.some(n => n.id === user.id));
}

const fullAccessCheck = (accounts, user) => {
	if (!accounts) {
		return [];
	}
	return accounts.filter(a => a.holders.some(h => (h.id === user.id) || a.authorisedPersons.some(a => a.id === user.id)));
}

const CustomerPortalPageNew = (props) => {

	const [loading, setLoading] = useState(true);
	const { storage, logout } = props;

	//	useEffect(() =>)

}


class CustomerPortalPage extends Component {
	state = { loading: true, thirdPartyList: [], fullAccessList: [] };

	constructor(props) {
		super(props);
		this.renderRouteComponent = this.renderRouteComponent.bind(this);
		this.renderRoute = this.renderRoute.bind(this);
	}


	componentDidMount() {
		const { storage, logout } = this.props;
		Promise.all([storage.getOrFetch(`/modules/crm/client/accounts?dynamicFields=CustodyType`), storage.getOrFetch("/users/current-user"), storage.getOrFetch("/modules/crm/client/addresses")]).then(([accounts, user, addresses]) => {

			let hasAccounts = !!accounts;
			if (hasAccounts) {
				//Only linked accounts
				accounts = accounts.filter((account) => !!getPortalAccType(account));
			} else {
				console.warn("Attempting to login with no accounts");
				logout();
			}
			if (!accounts) {
				console.warn("Attempting to login with no accounts that don't have portal access");
				logout();
			}
			const thirdPartyList = thirdPartyCheck(accounts, user);
			const fullAccessList = fullAccessCheck(accounts, user);
			this.setState({ thirdPartyList, fullAccessList });


			if (thirdPartyList && thirdPartyList.length) {
				if (fullAccessList && fullAccessList.length) {
					//TODO: get from path, if no path, show select screen.
					const { accountNumber } = this.props.match.params;
					this.setState({ isFullAccess: true, isThirdParty: true, showSwitchType: true })
				} else {
					this.setState({ isFullAccess: false, isThirdParty: true, showSwitchType: false })
				}
			} else {
				this.setState({ isFullAccess: true, isThirdParty: false, showSwitchType: false })
			}


			accounts = accounts.sort((a, b) => b.value - a.value);
			hasAccounts = hasAccounts && accounts.length > 0;
			if (hasAccounts) {
				//Load all portfolios
				accounts.forEach((account) => {
					this.setState(({ accPortfolioIsLoading }) => ({ accPortfolioIsLoading: { ...(accPortfolioIsLoading || {}), [account.id]: true } }));
					this.props.storage.getOrFetch(`/modules/crm/accounts/${account.id}/portfolio`).then((portfolioRemoteReliant) => {
						this.setState(({ accPortfolioIsLoading }) => ({ accPortfolioIsLoading: { ...(accPortfolioIsLoading || {}), [account.id]: false } }));
						if (portfolioRemoteReliant && !portfolioRemoteReliant.connectionError && portfolioRemoteReliant.data) {
							this.setState(({ accPortfolioMap }) => ({ accPortfolioMap: { ...(accPortfolioMap || {}), [account.id]: portfolioRemoteReliant.data } }));
						} else {
							this.setState(({ accPortfolioMap }) => ({ accPortfolioMap: { ...(accPortfolioMap || {}), [account.id]: null } }));
						}
					});
				});
				//We must rewrite the URL here or routing will not work.
				const location = window.location.href;
				if (location.indexOf("welcome") > -1) {
					this.props.history.push("..");
				}
				if (addresses && addresses.length > 0) {
					const address = addresses.find((address) => address.primary) || addresses[0];
					if (address) {
						user = { ...user, address };
					}
				}
				this.setState({ loading: false, accounts, user });
			} else {
				console.warn("Attempting to login with no linked accounts");
				logout();
			}
		});
	}

	renderRouteComponent(props, path, Component) {
		let { accounts, user, accPortfolioMap, accPortfolioIsLoading, thirdPartyList, fullAccessList } = this.state;
		if (!accPortfolioIsLoading) {
			accPortfolioIsLoading = {};
		}
		if (!accPortfolioMap) {
			accPortfolioMap = {};
		}
		const { logout } = this.props;
		const { accountNumber } = props.match.params;
		const switchAccount = (account) => { this.props.changeRoute(`/${account.number}` + path) };
		const switchToThirdParty = () => { this.props.changeRoute('/third-party' + LINK_ITEMS().THIRDPARTYACCESS.path) };
		const switchToAccountAccess = (account) => { this.props.changeRoute(`/${account.number}`+ LINK_ITEMS().DASHBOARD.path) };

		let accountInfo;
		if (accountNumber === 'third-party') {
			accountInfo = { account: {}, allAccounts: accounts, accounts: thirdPartyList, switchAccount, portalAccountType: null, user, logout, accPortfolioMap: {}, accPortfolioIsLoading: {} };

			return (
				<AccountInfo.Provider value={accountInfo} key={user.id}>
					<FiltersWrapper>
						<div id="customer-portal-page" className={` ${CommonFunctions.responsiveClassname()}`}>
							<Desktop>
								<SideNav logout={logout} thirdPartyView={true} showSwitchType={this.state.showSwitchType} switchToAccountAccess={() => switchToAccountAccess(this.state.fullAccessList[0])} />
							</Desktop>
							<div id="component-wrapper-container">
								<Component thirdPartyView={true} />
							</div>
						</div>
					</FiltersWrapper>
				 </AccountInfo.Provider>
			)
		}
		const account = CommonFunctions.getAccountFromNumber(accounts, accountNumber);
		if (!account) {
			console.warn(`Can't find account to match path ${accountNumber}, redirecting to root`);
			return <Redirect to={"/"} />
		}
		const portalAccountType = getPortalAccType(account);
		accountInfo = { account, allAccounts: accounts, accounts: fullAccessList, switchAccount, portalAccountType, user, logout, accPortfolioMap, accPortfolioIsLoading };
		const bannerActive = false;

		return (
			<AccountInfo.Provider value={accountInfo} key={account.number}>
				<FiltersWrapper>
					{bannerActive && (
						<Desktop>
							<AlertBanner
								message="System Upgrade - Portfolio Valuations & Trades may not be updated until Mon 3 Apr due to a System Upgrade. Apologies for any inconvenience."
							/>
						</Desktop>
					)}
					<div id="customer-portal-page" className={` ${bannerActive ? " alert-banner-active" : ""} ${CommonFunctions.responsiveClassname()}`}>
						<Desktop>
							<SideNav thirdPartyView={false} showSwitchType={this.state.showSwitchType} switchToThirdParty={() => switchToThirdParty()} />
						</Desktop>
						<div id="component-wrapper-container">
							<Component />
						</div>
					</div>
				</FiltersWrapper>
			</AccountInfo.Provider>
		);
	}

	renderRoute(linkItem, subLinkItem, idx) {
		//NB IAM:  Routes need a key for loop compatibility but adding the key causes a complete page rerender which is
		//then causing css transition issues.  For now key removed which will result in runtime console error but no
		//user impacting side effects
		if (subLinkItem) {
			return <Route path={BASE_LINK + linkItem.path + subLinkItem.path} exact render={(props) => this.renderRouteComponent(props, linkItem.path + subLinkItem.path, subLinkItem.component)} key={idx}/>;
		} else {
			return <Route path={BASE_LINK + linkItem.path} exact render={(props) => this.renderRouteComponent(props, linkItem.path, linkItem.component)} key={idx}/>;
		}
	}

	render() {
		const { loading, accounts } = this.state;
		if (loading) return <LoadingOverlay message="Getting account" />;
		else {
			return (
				<NotificationsWrapper>
					<MobileMenuWrapper>
						<Switch>
							{Object.keys(LINK_ITEMS()).map((key, idx) => {
								const link = LINK_ITEMS()[key];
								if (link.subPages) {
									return Object.keys(link.subPages).map((subKey, idx2) => this.renderRoute(link, link.subPages[subKey], idx + "_" + idx2));
								} else {
									return this.renderRoute(link, null, idx);
								}
							})}
							{this.state.isFullAccess && this.state.isThirdParty && <LoginViewSelect account={accounts[0].number} />}
							{accounts && accounts.length > 0 && !this.state.isThirdParty && <Redirect to={`/${accounts[0].number}` + LINK_ITEMS().DASHBOARD.path} />}
							{accounts && accounts.length > 0 && this.state.isThirdParty && <Redirect to={`/third-party` + LINK_ITEMS().THIRDPARTYACCESS.path} />}
						</Switch>
					</MobileMenuWrapper>
				</NotificationsWrapper>
			);
		}
	}
}

export default withRouteChange(CustomerPortalPage);
