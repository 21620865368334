import React, { useState, useEffect } from "react";
import Banner from "../../Banner";
import { LINK_ITEMS } from "../../CustomerPortalPage";
import BannerInfoText, { BannerInfoHighlightText, BannerInfoMainText } from "../../../../core-components/misc/BannerInfoText";
import { getPortalAccType, withAccountInfo, displayPortalAccType } from "../../../../core-components/contexts/AccountsContext";
import { withThreeSkyeGlobal } from "@threeskye/global";
import BannerNavLinks from "../../../../core-components/layouts/BannerNavLinks"
import PortalContentWrapper from "../../PortalContentWrapper";
import Mobile from "../../../../core-components/layouts/Mobile";
import DefaultMobileBanner from "../../DefaultMobileBanner";
import Desktop from "../../../../core-components/layouts/Desktop";
import CardContainer from "../../../../core-components/card/CardContainer";
import Card from "../../../../core-components/card/Card";
import CardHeader from "../../../../core-components/card/CardHeader";
import { Col, Row } from "reactstrap";
import { ModalTypes } from "../../../../core-components/modal/ModalUtils";
import DetailsLinkage from "./DetailsLinkage";
import RepeatableLinkage from "./RepeatableLinkage";
import moment from "moment";
import SaveChangesBanner from "./SaveChangesBanner";
import SinglePhoneField from '@threeskye/core-components/dist/components/ClientAccountDetails/Fields/SinglePhoneField/SinglePhoneField'
import { sectionFor, validateEmail } from "../../../../core-components/functions/DetailPageUtils";
import _ from "lodash";
import { SingleItem, SingleNumberEdit, SingleLocationField, SingleEmailField, SingleDropDownEdit, SingleDate, SingleCheckbox, SinglePlainTextField, BooleanAt, MonthEndField, SingleCountryField, MultiCountryField, ListOfFields, UniqueItem, UnstructuredButtonField, MultiField, OngoingInvestmentEdit, AdviserView, RemoteIdsView, TaxNumberField, BirthdayFields } from "@threeskye/core-components/dist/components/ClientAccountDetails/Fields/index"
import LoadingSpinner from "../../../../core-components/layouts/LoadingSpinner";
import { ContactSupportOutlined } from "@material-ui/icons";

const PersonalInformation = ({ accounts, account, storage, remote, user }) => {

	const [client, setClient] = useState({})
	const [clientSummary, setClientSummary] = useState({
		addresses: []
	})
	const [pending, setPending] = useState({})
	const [update, setUpdate] = useState({
		client: {
			addresses: [],
			interests: [],
			dependants: [],
			phoneNumbers: [],
			emailAddresses: [],
			taxNumbers: []
		},
		clientSummary: {
			addresses: [],
			twoFANumber: [],
			emailAddresses: [],
			taxNumbers: [],
		},
		unstructured: {},
		attachments: [],
		comments: "",
	})
	const [updateList, setUpdateList] = useState([])
	const [updateItems, setUpdateItems] = useState([])
	// const [changes, setChanges] = useState(null)
	const [detailsUpdateRequirements, setDetailsUpdateRequirements] = useState(null)
	const [fees, setFees] = useState([])
	const [rules, setRules] = useState([])
	const [errors, setErrors] = useState([])
	const [formulae, setFormulae] = useState([])
	const [schema, setSchema] = useState([])
	const [unstructured, setUnstructured] = useState({})
	const [submittingChange, setSubmittingChange] = useState(false)
	const [requirements, setRequirements] = useState(null);
	const [revertKey, setRevertKey] = useState(1);
	const [requiresDocs, setRequiresDocs] = useState(false)

	const generalIgnorableKeys = ["id"];
	const ignorableKeysByLabel = {
		"account.addresses": ["longitude", "latitude"],
		"clientSummary.addresses": ["longitude", "latitude"],
		"nonPersonClient.addresses": ["longitude", "latitude"],
		"account.bankAccounts": ["international", "country.key"],
		"accountPeripherals.debitAuthorities": ["type"],
		"accountPeripherals.creditApprovals": ["type"],
		"bankAccount.country": ["key"]
	}

	const getFriendlyChangeName = (updateItem) => {
		let { change } = updateItem;
		if (change.endsWith(".update")) {
			change = change.slice(0, -7);
		}
		const stripped = change;

		const bits = change.split(".");
		if (bits.length === 3) {
			change = bits[0] + "." + bits[1];
		}
		const filter = rule => rule.field === change && (bits.length === 3 ? rule.action === bits[2] : true);
		const rule = rules.filter(filter);

		const fieldName = (change = rule && rule[0] && rule[0].description ? rule[0].description : stripped);
		return { ...updateItem, fieldName };
	}

	const updateChanges = updateItems.map(getFriendlyChangeName)
	const changesMade =
		updateChanges &&
		updateChanges.length > 0 &&
		updateChanges.reduce((hasChanges, change) => hasChanges || (change.isRepeatable ? Object.keys(change.difference) : change.difference).length > 0, false);

	const getUnstructuredRepeatableValues = (field) => {
		const innerScopeUpdate = update.unstructured;

		const lookup = field.field.startsWith('unstructured') ? field.field.substring("unstructured.".length) : field.field;

		const unstructured = JSON.parse(account.unstructuredData || "{}");
		return {
			updates: (innerScopeUpdate && innerScopeUpdate[lookup]) || [],
			originals: (unstructured && unstructured[lookup]) || []
		}
	}

	const showUnstructuredValue = (field, onlyReturnInitialValue) => {
		if (!update || !update.unstructured) {
			return "";
		}
		//TODO currently only does strings
		const innerScopeUpdate = update.unstructured;
		const lookup = field.field.startsWith('unstructured') ? field.field.substring("unstructured.".length) : field.field;

		if (innerScopeUpdate[lookup] !== undefined && !onlyReturnInitialValue) {
			//ensure view stays empty if user clears (assumes component never sets value to undefined)
			return innerScopeUpdate[lookup];
		}

		const unstructured = JSON.parse(clientSummary.unstructuredData || "{}");
		if (unstructured && unstructured[lookup]) {
			return unstructured[lookup];
		}
		return "";
	}

	const isPending = (fields) => {
		if (Array.isArray(fields)) {
			//handle multiple
			const found = fields.reduce((prev, curr) => (isPending(curr) || prev), false);
			return found;
		}
		const innerScopePending = pending.raw;

		if (!innerScopePending) {
			return false;
		}
		const found = Object.keys(innerScopePending).find(key => key === fields) || Object.keys(innerScopePending).find(key => key === "unstructured." + fields);
		return found;
	}

	const updateUnstructuredField = (field, value, DisplayComponent) => {
		if (!field.field.startsWith("unstructured")) {
			field.field = "unstructured." + field.field;
		}
		updateChangeList(field.field, "update", value, DisplayComponent);

		let split = field.field.split(".");
		// eslint-disable-next-line
		split.slice(0, split.length - 1).reduce((parent, key) => parent[key], update)[split[split.length - 1]] = value;

		setUpdate(update);
	}

	const getActual = (test) => {
		if (test.startsWith("account.") || test.startsWith("client.") || test.startsWith("nonperson.")) {
			//Core field
			return update[test] || test
		} else {
			//Unstructured field
			return showUnstructuredValue({ field: 'unstructured.' + test });
		}
	}

	const fieldToDetailsLinkage = (field, fetchUniqueness, targetEntity) => {
		let values, nameForUpdates;
		let validatorFunc = field.validation;

		if (validatorFunc && validatorFunc.startsWith("hideIf")) {
			const wholeFormula = validatorFunc.substring(7, validatorFunc.length - 1);
			const clauses = wholeFormula.split(/&/);
			for (var j = 0; j < clauses.length; j++) {
				const formula = clauses[j];
				if (formula.startsWith("!")) {
					if (formula.startsWith("!!")) {
						const test = formula.substring(2);
						const actual = getActual.call(this, test);
						if (!!actual)
							return null;
					} else {
						const test = formula.substring(1);
						const actual = getActual.call(this, test);
						if (!actual)
							return null;
					}
				} else {
					let equals = false, notEquals = false;
					let operatorIndex = formula.indexOf('==');
					if (operatorIndex > 0) {
						equals = true;
					} else {
						operatorIndex = formula.indexOf('!=');
						if (operatorIndex > 0) {
							notEquals = true;
						}
					}
					if (equals || notEquals) {
						const test = formula.substring(0, operatorIndex);
						const value = formula.substring(operatorIndex + 2);

						let actual = getActual.call(this, test);

						//TODO not safe 
						if (actual != null && typeof actual === "object") {
							actual = actual.name;
						}

						if (equals) {
							if (actual === value || String(actual) === value) {
								return null;
							}
						} else if (notEquals) {
							if (actual !== value) {
								return null;
							}
						}
					}
				}
			}
		}

		// eslint-disable-next-line
		// const validation = validatorFunc ? Validators[validatorFunc] : null;

		const editable = field.currentRules?.find(r => r.action == null)?.editable;
		
		switch (field.type) {
			case "string":
				return (<DetailsLinkage
					editable={editable}
					label={field.title}
					value={showUnstructuredValue(field, true)}
					key={"unstructured_" + field.title}
					type="text"
					view={<SingleItem.View />}
					edit={<SingleItem.Edit />}
					pending={isPending(field.field)}
					onChange={val => { updateUnstructuredField(field, val, <SingleItem.Change label={field.title} />); }}
				/>);
			case "dropdown":
				return (<DetailsLinkage
					editable={editable}
					label={field.title}
					value={showUnstructuredValue(field, true)}
					key={"unstructured_" + field.title}
					type="dropdown"
					options={field.options}
					view={<SingleItem.View />}
					edit={<SingleItem.Edit />}
					pending={isPending(field.field)}
					onChange={val => { updateUnstructuredField(field, val, <SingleItem.Change label={field.title} />); }}
					revertKey={revertKey}
				/>
				);
			case "date":
				return (<DetailsLinkage
					editable={editable}
					label={field.title}
					value={showUnstructuredValue(field, true)}
					key={"unstructured_" + field.title}
					type="dropdown"
					options={field.options}
					view={<SingleDate.View />}
					edit={<SingleDate.Edit />}
					pending={isPending(field.field)}
					revertKey={revertKey}
					onChange={val => { updateUnstructuredField(field, val, <SingleDate.Change label={field.title} />); }}
				/>
				);
			case "boolean":
				return (<DetailsLinkage
					instantAdd
					editable={editable}
					label={field.title}
					omitLabel={true}
					value={showUnstructuredValue(field, true)}
					key={"unstructured_" + field.title}
					type="dropdown"
					options={field.options}
					view={<SingleCheckbox.View checkbox={<i className="material-icons">check_box</i>} check_box_outline_blank={<i className="material-icons">check_box_outline_blank</i>} />}
					edit={<SingleCheckbox.Edit checkbox={<i className="material-icons">check_box</i>} check_box_outline_blank={<i className="material-icons">check_box_outline_blank</i>} editable={editable} />}
					pending={isPending(field.field)}
					onChange={val => { updateUnstructuredField(field, val, <SingleCheckbox.Change label={field.title} checkbox={<i className="material-icons">check_box</i>} check_box_outline_blank={<i className="material-icons">check_box_outline_blank</i>} />); }}
					className="checkbox-field-outer"
				/>
				);
			case "booleanAt":
				return (<DetailsLinkage
					editable={editable}
					label={field.title}
					value={showUnstructuredValue(field, true)}
					key={"unstructured_" + field.title}
					type="dropdown"
					options={field.options}
					view={<BooleanAt.View />}
					edit={<BooleanAt.Edit clearLabel={"Unset"} />}
					pending={isPending(field.field)}
					onChange={val => { updateUnstructuredField(field, val, <BooleanAt.Change label={field.title} />); }}
					className="checkbox-date-field-outer"
					revertKey={revertKey}
				/>
				);
			case "integer":
			case "double":
			case "number":
				return (<DetailsLinkage
					editable={editable}
					label={field.title}
					value={showUnstructuredValue(field, true)}
					key={"unstructured_" + field.title}
					view={<SingleItem.View />}
					edit={<SingleNumberEdit />}
					pending={isPending(field.field)}
					onChange={val => { updateUnstructuredField(field, val, <SingleItem.Change label={field.title} />); }}
				/>
				);
			case "list":
				return (<DetailsLinkage
					editable={editable}
					label={field.title}
					value={showUnstructuredValue(field, true)}
					key={"unstructured_" + field.title}
					type="dropdown"
					options={field.options}
					view={<SingleItem.View />}
					edit={<SingleDropDownEdit />}
					pending={isPending(field.field)}
					onChange={val => { updateUnstructuredField(field, val, <SingleItem.Change label={field.title} />); }}
					revertKey={revertKey}
				/>
				);
			case "monthEnd":
				return (<DetailsLinkage
					editable={editable}
					label={field.title}
					value={showUnstructuredValue(field, true)}
					key={"unstructured_" + field.title}
					type="monthEnd"
					view={<MonthEndField.View />}
					edit={<MonthEndField.Edit />}
					pending={isPending(field.field)}
					onChange={val => { updateUnstructuredField(field, val, <MonthEndField.Change label={field.title} />); }}
				/>
				);
			// case "calculated":
			// 	calculateValue.call(this, field.field);
			// 	return (<DetailsLinkage 
			// 		editable={editable} 
			// 		label={field.title}
			// 		value={stateAssembled[field.field]} 
			// 		key={"unstructured_"+field.title}
			// 		view={<SingleItem.View />}
			// 		pending={false}
			// 		onChange={()=>{}}
			// 		/>
			// 	);
			case "address":
				values = getUnstructuredRepeatableValues(field);
				nameForUpdates = "unstructured." + field.field;
				if (!update.unstructured[field.field])
					update.unstructured[field.field] = [];

				return <RepeatableLinkage
					editable={editable}
					key={"unstructured_" + field.title}
					idPrefix="address-"
					data={showRepeatableValues(values.updates, values.originals) || []}
					update={update.unstructured[field.field]}
					onAdd={() => addRepeatable(nameForUpdates, nameForUpdates, { name: "Address", content: { fullAddress: "" } }, <SingleLocationField.Change typeLabel={field.title} />)}
					onDelete={(data, update, index) => deleteRepeatable(nameForUpdates, nameForUpdates, data[index], <SingleLocationField.Change typeLabel={field.title} />)}
					label={(data, update, index) => field.title + ": " + data[index].name}
					value={(data, update, index) => data[index]}
					keyBase="address-"
					view={<SingleLocationField.View />}
					edit={<SingleLocationField.Edit />}
					add={<SingleLocationField.Add />}
					addPending={isPending(nameForUpdates)}
					instancePending={(contentId) => isPending(`${nameForUpdates}.${contentId}`)}
					onChange={(data, update, index, val) => modifyRepeatable(nameForUpdates, nameForUpdates, val, <SingleLocationField.Change typeLabel={field.title} />)}
					fieldTypeLabel={field.title || "Address"}
					revertKey={revertKey}
				/>;
			case "emailAddress":
				values = getUnstructuredRepeatableValues(field);
				nameForUpdates = "unstructured." + field.field;
				if (!update.unstructured[field.field])
					update.unstructured[field.field] = [];

				return <RepeatableLinkage
					editable={editable}
					key={"unstructured_" + field.title}
					idPrefix="email-"
					data={showRepeatableValues(values.updates, values.originals) || []}
					update={update.unstructured[field.field]}
					onAdd={() => addRepeatable(nameForUpdates, nameForUpdates, { name: "", content: { name: "" } }, <SingleEmailField.Change typeLabel={field.title} />)}
					onDelete={(data, update, index) => deleteRepeatable(nameForUpdates, nameForUpdates, data[index], <SingleEmailField.Change typeLabel={field.title} />)}
					value={(data, update, index) => data[index]}
					label={(data, update, index) => field.title + ": " + data[index].name}
					keyBase="email-address-"
					view={<SingleEmailField.View />}
					edit={<SingleEmailField.Edit />}
					add={<SingleEmailField.Add />}
					addPending={isPending(nameForUpdates)}
					instancePending={(contentId) => isPending(`${nameForUpdates}.${contentId}`)}
					onChange={(data, update, index, val) => {
						modifyRepeatable(nameForUpdates, nameForUpdates, val, <SingleEmailField.Change typeLabel={field.title} />);
					}}
					fieldTypeLabel={field.title || "EmailAddress"}
				/>;
			case "repeatedText":
				values = getUnstructuredRepeatableValues(field);
				nameForUpdates = "unstructured." + field.field;
				if (!update.unstructured[field.field])
					update.unstructured[field.field] = [];

				return <RepeatableLinkage
					editable={editable}
					key={"unstructured_" + field.title}
					idPrefix="repeated-text-"
					data={showRepeatableValues(values.updates, values.originals) || []}
					update={update.unstructured[field.field]}
					onAdd={() => addRepeatable(nameForUpdates, nameForUpdates, { name: "", content: { name: "" } }, <SinglePlainTextField.Change typeLabel={field.title} />)}
					onDelete={(data, update, index) => deleteRepeatable(nameForUpdates, nameForUpdates, data[index], <SinglePlainTextField.Change typeLabel={field.title} />)}
					value={(data, update, index) => data[index]}
					label={(data, update, index) => field.title + ": " + data[index].name}
					keyBase="repeated-text-"
					view={<SinglePlainTextField.View />}
					edit={<SinglePlainTextField.Edit />}
					add={<SinglePlainTextField.Add />}
					addPending={isPending(nameForUpdates)}
					instancePending={(contentId) => isPending(`${nameForUpdates}.${contentId}`)}
					onChange={(data, update, index, val) => {
						modifyRepeatable(nameForUpdates, nameForUpdates, val, <SinglePlainTextField.Change typeLabel={field.title} />);
					}}
					fieldTypeLabel={field.title}
				/>;
			case "country":
				return (
					<DetailsLinkage
						editable={editable}
						label={field.title}
						value={showUnstructuredValue(field)}
						key={"unstructured_" + field.title}
						view={<SingleCountryField.View />}
						edit={<SingleCountryField.Edit />}
						pending={isPending(field.field)}
						onChange={(val) => updateUnstructuredField(field, val, <SingleCountryField.Change label={field.title} />)}
						revertKey={revertKey}
					/>
				);
			case "countries":
				return (
					<DetailsLinkage
						editable={editable}
						label={field.title}
						value={showUnstructuredValue(field)}
						key={"unstructured_" + field.title}
						view={<MultiCountryField.View />}
						edit={<MultiCountryField.Edit />}
						pending={isPending(field.field)}
						onChange={(val) => updateUnstructuredField(field, val, <MultiCountryField.Change label={field.title} />)}
						revertKey={revertKey}
					/>
				);
			case "listOfFields":
				values = getUnstructuredRepeatableValues(field);
				nameForUpdates = "unstructured." + field.field;
				if (!update.unstructured[field.field])
					update.unstructured[field.field] = [];


				return <RepeatableLinkage
					editable={editable}
					key={"unstructured_" + field.title}
					idPrefix="list-of-fields-"
					data={showRepeatableValues(values.updates, values.originals) || []}
					fields={field.options}
					update={update.unstructured[field.field]}
					onAdd={() => addRepeatable(nameForUpdates, nameForUpdates, { name: "", content: { data: null } }, <ListOfFields.Change typeLabel={field.title} fields={field.options} />)}
					onDelete={(data, update, index) => deleteRepeatable(nameForUpdates, nameForUpdates, data[index], <ListOfFields.Change typeLabel={field.title} fields={field.options} />)}
					fieldTitle={field.title}
					label={(data, update, index) => field.title + ": " + data[index].name}
					value={(data, update, index) => data[index]}
					keyBase="list-of-fields-"
					view={<ListOfFields.View />}
					edit={<ListOfFields.Edit />}
					add={<ListOfFields.Add />}
					addPending={isPending(nameForUpdates)}
					instancePending={(contentId) => isPending(`${nameForUpdates}.${contentId}`)}
					onChange={(data, update, index, val) => modifyRepeatable(nameForUpdates, nameForUpdates, val, <ListOfFields.Change typeLabel={field.title} fields={field.options} />)}
					fieldTypeLabel={field.title}
					revertKey={revertKey}
				/>;
			case "unique":
				const lookup = field.field.startsWith('unstructured.') ? field.field.substring("unstructured.".length) : field.field;
				return (<DetailsLinkage
					editable={editable}
					label={field.title}
					value={showUnstructuredValue(field)}
					key={"unstructured_" + field.title}
					type="text"
					view={<UniqueItem.View />}
					edit={<UniqueItem.Edit />}
					pending={isPending(field.field)}
					onChange={val => { updateUnstructuredField(field, val, <UniqueItem.Change label={field.title} />); }}
					fetchUniqueness={(value) => fetchUniqueness(lookup, value)}
				/>);
			case "ongoingInvestment":
				const groupedFields = field.groupedFields;
				return (
					<DetailsLinkage
						editable={editable}
						label={field.title}
						editSublabels={groupedFields.map(f => f.title)}
						value={[
							showUnstructuredValue(groupedFields[0]),
							showUnstructuredValue(groupedFields[1])
						]}
						key={"unstructured_" + field.title}
						view={<MultiField.View addDividers />}
						edit={<OngoingInvestmentEdit frequencyField={groupedFields[0]}
						/>}
						pending={isPending([groupedFields[0].field, groupedFields[1].field])}
						onChange={(val) => {
							updateUnstructuredField(groupedFields[0], val[0], <MultiField.Change label={groupedFields[0].title} />)
							updateUnstructuredField(groupedFields[1], val[1], <MultiField.Change label={groupedFields[1].title} />)
						}
						}
					/>
				);
			case "button":
				return (<UnstructuredButtonField key={"unstructured_" + field.title} label={field.title} endPoint={field.defaultValue} targetEntity={targetEntity} />);
			default:
		}
		return <p>field type {field.type} not implemented yet</p>
	}

	const currentValueResolver = (label) => {
		if (label.startsWith("clientSummary")) {
			return clientSummary[label.substring("clientSummary".length + 1, label.length)];
		} else if (label.startsWith("client")) {
			return client[label.substring("client".length + 1, label.length)];
		} else if (label.startsWith("unstructured")) {
			const unstructuredData = (clientSummary.unstructuredData && JSON.parse(clientSummary.unstructuredData)) || {};
			return unstructuredData[label.substring("unstructured".length + 1, label.length)];
		}
	}

	const fetchUniqueness = function (dto) {
		return remote.post(`/modules/crm/provider/duplicate-check`, dto).then((duplicateCheckResponse) => {
			if (duplicateCheckResponse) {
				if (duplicateCheckResponse.success) {
					return { isValid: true };
				}
				return { isValid: false, errorMessage: duplicateCheckResponse.message };
			}
			return { isValid: false, errorMessage: "System error. Please try again later." };
		});
	};

	const getDifference = (currentValue, newValue, label) => { // label should really be called path.
		return [{ oldValue: currentValue, newValue, label }];
	}

	const showableChanges = (difference, label) => {
		let ignorableKeys = generalIgnorableKeys;
		if (ignorableKeysByLabel[label]) {
			ignorableKeys = ignorableKeys.concat(ignorableKeysByLabel[label]);
		}

		return difference.filter((data) => {
			var relativePath = data.label.startsWith(label) ? data.label.substring(label.length + 1) : data.label;
			// for arrays, we want to use the key to filter and for objects we want to use the relative path.
			return !ignorableKeys.includes(data.key) && !ignorableKeys.includes(relativePath);
		});
	}

	const getRequirements = (changedFields = updateList) => {
		remote.post("/modules/crm/client/details-update-requirements/HWP", changedFields.map((chng) => (chng.endsWith(".update") ? chng.slice(0, -7) : chng)))
		.then((response) => {
			const listOfFieldsRequiringDocs = Object.keys(response.data.docsByField)
			let allFieldsInSchema = []
			schema.forEach((group) => {
				group.fields.forEach((field) => {
					allFieldsInSchema.push(field)
				})
			})
			const requirementsWithTitles = listOfFieldsRequiringDocs.map((field) => {
				let schemaField = allFieldsInSchema.find((schemaField) => schemaField.field === field)
				let obj = { field, docs: response.data.docsByField[field], title: schemaField.title }
				return obj
			})
			const listOfDocsRequired = requirementsWithTitles.map(field => {
				return field.docs
			}).flat()
			const requiresDocs = listOfDocsRequired.length > 0
			
			setRequiresDocs(!!requiresDocs)
			if (requiresDocs) {
				setDetailsUpdateRequirements(requirementsWithTitles)
			} else {
				setDetailsUpdateRequirements(null)
			}
		});
	}

	// ************************************** REPEATABLES **************************************

	const addRepeatable = (path, updatePath, value, DisplayComponent) => {
		if (value.content && (value.content.id === null || value.content.id === undefined)) {
			value.content.id = Math.floor(-Math.random() * 100000);
		}
		value.isAdd = true;
		const bits = updatePath.split(".");

		const holder = update[bits[0]]; //eg account, client, accountPeripherals, clientSummary
		let array = holder[bits[1]]; //eg. addresses, emailAddresses
		if (!array) {
			array = [];
			holder[bits[1]] = array;
		}
		array.push(value);

		const newUpdateList = [...updateList];
		const newUpdateItems = [...updateItems];
		const addPath = path + ".add";
		if (newUpdateList.indexOf(addPath) < 0) {
			newUpdateList.push(addPath);
			newUpdateItems.push({ change: addPath, difference: {}, isRepeatable: true, DisplayComponent });
		}

		// this.setState({ update, updateList });
		setUpdate(update)
		setUpdateList(newUpdateList)
		setUpdateItems(newUpdateItems)
		getRequirements(newUpdateList);
	}

	const deleteRepeatable = (path, updatePath, value, DisplayComponent) => {
		const bits = updatePath.split(".");

		const holder = update[bits[0]]; //either account or accountPeripherals
		const array = holder[bits[1]]; //eg. addresses

		const newUpdateList = [...updateList];
		const newUpdateItems = [...updateItems];

		const indexInUpdate = array.findIndex(item => item.content && item.content.id === value.content.id);

		//Parse if necessary (unstructured)
		let currentValue = { content: {} };
		if (value.content.id >= 0) {
			let data = currentValueResolver(path);
			if (typeof data === "string") {
				data = JSON.parse(data);
			}
			currentValue = data.find(item => item.content && item.content.id === value.content.id);
		}
		// const currentValueContent = currentValue.content;

		if (value.content.id < 0) {
			//removing a newly added...
			if (indexInUpdate >= 0) {
				array.splice(indexInUpdate, 1);

				const changeIndex = newUpdateList.indexOf(path + ".add");
				delete newUpdateItems[changeIndex].difference[value.content.id];

				//check whether we still need the update in updateList
				if (Object.keys(newUpdateItems[changeIndex].difference).length === 0) {
					//no more adds
					newUpdateItems.splice(changeIndex, 1);
					newUpdateList.splice(changeIndex, 1);
				}

			}
			// this.setState({ update, updateList });
			setUpdate(update)
			setUpdateItems(newUpdateItems)
			setUpdateList(newUpdateList)
			return;
		}


		if (indexInUpdate >= 0) {
			array[indexInUpdate].isDelete = true;
		} else {
			array.push(value);
			value.isDelete = true;
		}

		const deletePath = path + ".delete";
		const changeIndex = newUpdateList.indexOf(deletePath);

		const difference = getDifference(currentValue.content, {}, path).concat(getDifference(currentValue.name, "", path + ".name"));

		if (changeIndex < 0) {
			//no deletes yet
			newUpdateList.push(deletePath);
			newUpdateItems.push({ change: deletePath, difference: { [value.content.id]: showableChanges(difference, path) }, isRepeatable: true, DisplayComponent });
		} else {
			//has deletes
			newUpdateItems[changeIndex].difference[value.content.id] = showableChanges(difference, path);

		}
		// this.setState({ update, updateList });
		setUpdate(update)
		setUpdateItems(newUpdateItems)
		setUpdateList(newUpdateList)
		getRequirements(newUpdateList);
	}

	const modifyRepeatable = (path, updatePath, value, DisplayComponent) => {
		const originalPath = path;
		const bits = updatePath.split(".");

		delete value.isAdd;
		delete value.content.isAdd;

		const holder = update[bits[0]]; //either account or accountPeripherals
		const array = holder && holder[bits] && holder[bits[1]] ? holder[bits[1]] : []; //eg. addresses

		const indexInUpdate = array.findIndex(item => item.content && item.content.id === value.content.id);
		if (indexInUpdate >= 0) {
			array.splice(indexInUpdate, 1, value);
		} else {
			array.push(value);
		}

		const newUpdateList = [...updateList];
		const newUpdateItems = [...updateItems];

		if (value.content.id < 0) {
			path = path + ".add";
		}

		const changeIndex = newUpdateList.indexOf(path);

		//Parse if necessary (unstructured)
		let currentValue = { content: {} };
		if (value.content.id >= 0) {
			let data = currentValueResolver(originalPath);
			if (typeof data === "string") {
				data = JSON.parse(data);
			}
			currentValue = data.find(item => item.content && item.content.id === value.content.id);
		}
		const difference = getDifference(currentValue.content, value.content, originalPath).concat(getDifference(currentValue.name, value.name, originalPath + ".name"));

		if (changeIndex < 0) {
			//repeatable has not been modified before
			if (difference.length > 0) {
				let changed = difference.reduce((prev, curr) => prev || !_.isEqual(curr.oldValue, curr.newValue), false);

				//has difference
				if (changed) {
					newUpdateList.push(path);
					newUpdateItems.push({ change: path, difference: { [value.content.id]: showableChanges(difference, originalPath) }, isRepeatable: true, DisplayComponent });
				}
			}
		} else {
			//repeatable has been modified before
			if (difference.reduce((eq, diff) => eq && _.isEqual(diff.oldValue, diff.newValue), true)) {
				//Back to original
				//no difference
				delete newUpdateItems[changeIndex].difference[value.content.id]
				if (Object.keys(newUpdateItems[changeIndex].difference).length === 0) {
					//no more difference
					newUpdateItems.splice(changeIndex, 1);
					newUpdateList.splice(changeIndex, 1);
				}
			} else {			//has difference
				newUpdateItems[changeIndex].difference[value.content.id] = showableChanges(difference, originalPath);
			}
		}
		let newUpdate = JSON.parse(JSON.stringify(update))
		newUpdate[bits[0]][bits[1]] = [...update[bits[0]][bits[1]], ...array]
	
		setUpdate(newUpdate)
		setUpdateItems(newUpdateItems)
		setUpdateList(newUpdateList)
		getRequirements(newUpdateList);
	}

	const generateId = () => {

		return -Math.floor(Math.random() * 100000);
	}

	const testInternationalNumberSeperately = (idd, number) => {
		return !!idd && /^\+[0-9]{1,4}$/.test(idd.replace(/\s/g, "")) //assumes that the prefix is a string!!!
			&& /^[1-9][0-9]{6,10}$/.test(number);//also total length test?
	}

	const showRepeatableValues = (updateValues, initialValues) => {
		if (!initialValues) {
			return updateValues || [];
		}
		const values = [];
		//go through initial, replacing any that have been modified
		initialValues.forEach(element => {
			const updatedElement = updateValues.find(val => val.content.id === element.content.id);
			if (updatedElement && updatedElement.isDelete) {
				//ignore this one
			} else if (updatedElement) {
				values.push(updatedElement);
			} else {
				values.push(element);
			}
		});

		//look through updates for adds
		if (updateValues) {
			updateValues.forEach(element => {
				if (element.content && element.content.id < 0 && !element.isDelete) {
					values.push(element);
				}
			});
		}

		return values;
	}

	// ************************************** NON-REPEATABLES **************************************

	const updateField = (label, change, DisplayComponent) => {
		let split = label.replace(/\[/g, ".").replace(/\]/g, "").split(".");
		let newUpdate = { ...update };
		// eslint-disable-next-line
		split.slice(0, -1).reduce((parent, key) => {
			if (!parent[key]) {
				parent[key] = {};
			}
			return parent[key];
		}, newUpdate)[split[split.length - 1]] = change;
		setUpdate(newUpdate);
		updateChangeList(label, "update", change, DisplayComponent);
	}

	const updateChangeList = (label, change, value, DisplayComponent,) => {
		/**
		 * change possibles
		 * client.value.update
		 * client.list.add
		 * client.list.i.update
		 * client.list.i.remove
		 *
		 * label = "client.value", change = "update/add/etc."
		 */

		const changes = [...updateList];
		const newUpdateItems = [...updateItems];

		let currentValue = currentValueResolver(label);

		const changeIndex = changes.findIndex(e => {
			return e.indexOf(label) === 0;
		});

		const difference = getDifference(currentValue, value, label);
		if (changeIndex > -1) {
			let reset = false;
			if (currentValue === undefined) {
				//This was a field that wasn't originally set - we've set it
				//But have we potentially also unset it?
				if (value === false || value === "" || value === null) {
					reset = true;
				}
			} else if (_.isEqual(difference[0].oldValue, difference[0].newValue)) {
				reset = true;
			}

			if (reset) {
				// back to original state
				changes.splice(changeIndex, 1);
				newUpdateItems.splice(changeIndex, 1);
			} else {
				// update differences
				const newChange = label.concat(".", change);
				newUpdateItems[changeIndex] = { difference: difference, change: newChange, isRepeatable: false, DisplayComponent };
			}
		} else {
			if (difference.length > 0 && !_.isEqual(difference[0].oldValue, difference[0].newValue) /*&& !(currentValue === undefined && (value === false || value === ""))*/) {

				// add new changes
				const newChange = label.concat(".", change);
				changes.push(newChange);
				newUpdateItems.push({ difference, change: newChange, isRepeatable: false, DisplayComponent });
			}
		}

		//set our state
		setUpdateItems(newUpdateItems)
		setUpdateList(changes)
		getRequirements(changes);
	}

	const removeChanges = () => {
		setUpdate({
			client: {
				addresses: [],
				interests: [],
				dependants: [],
				phoneNumbers: [],
				emailAddresses: [],
				taxNumbers: []
			},
			clientSummary: {
				addresses: [],
				twoFANumber: [],
				emailAddresses: [],
				taxNumbers: [],
			},
			unstructured: {},
			attachments: [],
			comments: "",
		})
		setUpdateItems([])
		setUpdateList([])
		setRevertKey(revertKey + 1);
	}

	const setData = () => {
		Promise.all([
			storage.getOrFetch("/modules/crm/middle-office/business-rules"),
			storage.getOrFetch("/modules/crm/client/details"),
			storage.getOrFetch("/modules/crm/client/schema/HWP/client/requirements"),
		]).then(([rules, summary, schema]) => {
			setRules(rules)
			setClientSummary(summary)
			setClient(summary)
			setPending(initPending(summary))
			return schema
		}).then(s => setSchema(s.groups))
	};

	useEffect(() => {
		setData()
	}, [])

	const initPending = (singlePage) => {
		var tempPending = {}
		if (singlePage.pending == null)
			return tempPending;//return an empty object so that accessing fields will be detected as null
		/** 
		 * Using sectionFor, look at each key in the pending object
		 * Append the labelFor a field to the pending of an 
		 */
		for (var key in singlePage.pending) {
			let section = sectionFor(key.split("."));
			if (tempPending[section] == null)
				tempPending[section] = []//"Pending changes:"]
			let label = singlePage.pending[key][0].fieldName;//labelFor(key, singlePage.pending[key]);
			tempPending[section].push(label);
		}
		/**
		 * Use this part iff: using message list not message
		 * Take all of the lists, convert them into single strings
		 */
		for (var section in tempPending) {
			tempPending[section] = "Pending changes: " + tempPending[section].join(", ")//in string from
		}
		tempPending.raw = singlePage.pending;
		return tempPending;
	}
	// Function to display values that are used with DetailsLinkage.js on the client, non persons, and accounts details pages.
	const showValue = (updateValue, initialValue) => {
		const value = updateValue === "" ? "" : (updateValue || initialValue);
		return value;
	}

	const renderCore = (schemaObj) => {
		const groupedFields = schemaObj.groupedFields;
		
		const editable = schemaObj.currentRules?.find(r => r.action == null)?.editable;
		const addable = schemaObj.currentRules?.find(r => r.action == 'add')?.editable;
		const deletable = schemaObj.currentRules?.find(r => r.action == 'delete')?.editable;

		
		switch (schemaObj.field) {
			case "clientSummary.givenName":
				return (<DetailsLinkage
					editable={editable}
					label={"Given Name"}
					value={showValue(update.clientSummary.givenName, clientSummary.givenName)}
					key={"givenName"}
					type="text"
					view={<SingleItem.View />}
					edit={<SingleItem.Edit />}
					pending={isPending("clientSummary.givenName")}
					onChange={val => {
						updateField("clientSummary.givenName", val, <MultiField.Change label={"Given Name"} />);
					}}
					validate={val => { return { isValid: val } }}
				/>);
			case "clientSummary.middleNames":
				return (<DetailsLinkage
					editable={editable}
					label={"Middle Names"}
					value={showValue(update.clientSummary.middleNames, clientSummary.middleNames)}
					key={"middleNames"}
					type="text"
					view={<SingleItem.View />}
					edit={<SingleItem.Edit />}
					pending={isPending("clientSummary.middleNames")}
					onChange={val => {
						updateField("clientSummary.middleNames", val, <MultiField.Change label={"Middle Names"} />);
					}}
					validate={val => { return { isValid: val } }}
				/>);
			case "clientSummary.familyName":
				return (<DetailsLinkage
					editable={editable}
					label={"Family Name"}
					value={showValue(update.clientSummary.familyName, clientSummary.familyName)}
					key={"familyName"}
					type="text"
					view={<SingleItem.View />}
					edit={<SingleItem.Edit />}
					pending={isPending("clientSummary.familyName")}
					onChange={val => {
						updateField("clientSummary.familyName", val, <MultiField.Change label={"Family Names"} />);
					}}
					validate={val => { return { isValid: val } }}
				/>);
			case "dropdown":
			case "legalName":
				return <DetailsLinkage
					editable={editable}
					label={"Legal Name"}
					editLabel=""
					editSublabels={groupedFields.map(f => f.title)}
					value={[
						showValue(update.clientSummary.givenName, clientSummary.givenName),
						showValue(update.clientSummary.middleNames, clientSummary.middleNames),
						showValue(update.clientSummary.familyName, clientSummary.familyName)
					]}
					key="legalName"
					view={<MultiField.View />}
					edit={<MultiField.Edit />}
					pending={isPending(["clientSummary.givenName", "clientSummary.middleNames", "clientSummary.familyName"])}
					onChange={val => {
						updateField("clientSummary.givenName", val[0], <MultiField.Change label={groupedFields[0].title || "Given Name"} />);
						updateField("clientSummary.middleNames", val[1], <MultiField.Change label={groupedFields[1].title || "Middle Names"} />);
						updateField("clientSummary.familyName", val[2], <MultiField.Change label={groupedFields[2].title || "Family Name"} />);
					}}
					validate={val => { return { isValid: val && val[0] && val[2] && val[0].length > 0 && val[2].length > 0 } }}
				/>;
			case "client.birthDate":
				return (<DetailsLinkage
					instantAdd
					noAutoFocus
					editable={editable}
					label={"Birth Date"}
					value={clientSummary.birthDate}
					key={"birthDate"}
					type="dropdown"
					options={[]}
					view={< SingleDate.View />}
					edit={< SingleDate.Edit editable={editable} />}
					pending={isPending("client.birthDate")}
					onChange={val => {
						updateField("client.birthDate", val, <SingleDate.Change label={("Birth Date")} />);
					}}
					validate={val => ({ isValid: moment(val).isValid() })}
					revertKey={revertKey}
				/>
				);
			default:
		}

		//Rest are repeatables
		const key = schemaObj.field + "Repeatable";
		let displayTitle = "", updateData, dataData, blankForInsert = {}, refName = schemaObj.field, Component, OverrideView = null, label, extraProps = {};

		switch (schemaObj.type) {
			case "addresses":
				displayTitle = schemaObj.title || "Address";
				updateData = update.client.addresses;
				dataData = clientSummary.addresses;
				blankForInsert = { name: "", content: { fullAddress: "", additionalData: "{}" } };
				Component = SingleLocationField;
				label = (data, update, index) => data[index].name || displayTitle;
				extraProps = { possibleLabels: schemaObj.possibleLabels ? [...schemaObj.possibleLabels] : null }
				break;
			case "phones":
				displayTitle = schemaObj.title || "Phone";
				updateData = update.client.phoneNumbers;
				dataData = clientSummary.phoneNumbers;
				blankForInsert = { name: "", content: { extension: "", idd: "", number: "" } };
				Component = SinglePhoneField;
				label = (data, update, index) => (data[index].name ? data[index].name : displayTitle);
				extraProps = {
					possibleLabels: schemaObj.possibleLabels ? [...schemaObj.possibleLabels] : null,
					validate: ({ content }) => ({ isValid: testInternationalNumberSeperately("+" + (content.idd && content.idd.replace ? content.idd.replace("+", "") : content.idd), content.number) }),
				};
				break;
			case "taxNumbers":
				displayTitle = schemaObj.title || "Tax Number";
				updateData = update.client.taxNumbers;
				dataData = clientSummary.taxNumbers;
				refName = schemaObj.field;
				blankForInsert = { name: "Tax Numbers", content: { country: "", tin: "" } };
				Component = TaxNumberField;
				label = (data, update, index) => (data[index].name ? data[index].name : displayTitle);
				extraProps = {
					validate: (val) => { return val && val.valid ? val.valid : { isValid: true } },
					validator: schemaObj.validation
				}
				break;

			case "emailAddresses":
				displayTitle = schemaObj.title || "Email Address";
				updateData = update.client.emailAddresses;
				dataData = clientSummary.emailAddresses;
				blankForInsert = { name: "", content: { id: generateId(), name: "", isAdd: true } };
				Component = SingleEmailField;
				label = (data, update, index) => data[index].name;
				extraProps = {
					possibleLabels: schemaObj.possibleLabels ? [...schemaObj.possibleLabels] : null,
					validate: ({ content }) => (validateEmail(content.name))
				}

				break;
			default:
				return <p>unrecognised field: {schemaObj.field}</p>
		}

		if (!Component) {
			return <p>No component for field: {schemaObj.field}</p>
		}

		if (!showRepeatableValues(updateData, dataData) || showRepeatableValues(updateData, dataData) < 1) {
			return null
		}


		return <RepeatableLinkage
			key={key}
			idPrefix={schemaObj.field}
			data={showRepeatableValues(updateData, dataData) || []}
			update={updateData || []}
			onAdd={() => addRepeatable(refName, refName, blankForInsert, <Component.Change typeLabel={displayTitle} />, update, setUpdate, updateList, setUpdateList, updateItems, setUpdateItems)}
			onDelete={(data, update, index) => deleteRepeatable(refName, refName, data[index], <Component.Change typeLabel={displayTitle} />)}
			label={label}
			value={(data, update, index) => data[index]}
			keyBase={key}
			view={OverrideView || <Component.View />}
			edit={<Component.Edit />}
			add={<Component.Add />}
			addPending={true}
			instancePending={(contentId) => isPending(`${refName}.${contentId}`)}
			onChange={(data, update, index, val) => modifyRepeatable(refName, refName, val, <Component.Change typeLabel={displayTitle} />)}
			fieldTypeLabel={displayTitle}
			editable={editable}
			revertKey={revertKey}
			{...extraProps}
		/>;
	}

	// ************ modal ************** //
	const onAttachmentsChange = (newAttachments) => {
		setUpdate({ ...update, attachments: newAttachments })
	}

	const getSubmission = () => {
		const submission = {
			event: {
				source: "sysgen"
			},
			updates: updateList.map((label) => {
				if (label.endsWith(".update")) {
					return label.slice(0, -7)
				}
				return label;
			}),
			client: update.client,
			clientSummary: update.clientSummary,
			unstructuredData: update.unstructured,
			attachments: update.attachments,
			comments: update.comments
		};
		submission.client.id = client.id

		return submission;
	}

	const submitChangeRequest = () => {
		const submission = getSubmission();
		setSubmittingChange(true)
		remote.post("/modules/crm/client/details-update/HWP", submission).then((resp) => {
			if (resp.message) {
				changesSubmitted(false)
				let remoteErrors;
				try {
					remoteErrors = JSON.parse(resp.message);
				} catch (err) {
					remoteErrors = [resp.message];
				}
				setErrors(remoteErrors)
			} else {
				storage.refresh("/users/current-user").then(setClient)
				storage.refresh("/modules/crm/client/details").then((resp) => {
					setClientSummary(resp)
					setPending(initPending(resp))
				})
				setUpdate({
					client: {
						addresses: [],
						interests: [],
						dependants: [],
						phoneNumbers: [],
						emailAddresses: [],
						taxNumbers: []
					},
					clientSummary: {
						addresses: [],
						twoFANumber: [],
						emailAddresses: [],
						taxNumbers: [],
					},
					unstructured: {},
					attachments: [],
					comments: "",
				})
				setUpdateList([])
				setUpdateItems([])
				setDetailsUpdateRequirements(null)
				setRequiresDocs(false)
				changesSubmitted(true)
			}
		}).then(() => {
			setSubmittingChange(false)
		});
	}

	const changesSubmitted = (success) => {
		storage.get("portal.modals").close();
		storage.get("portal.modals").show(ModalTypes.ChangesSubmittedModal, { success });
	}

	const rollbackAttachment = () => {
		setUpdate({ ...update, attachments: [] });
	}

	const openModal = () => {
		storage.get("portal.modals").show(ModalTypes.SupportingDocumentationModal, { onAttachmentsChange, submitChangeRequest, rollbackAttachment, detailsUpdateRequirements, account: account, clearAttachments });
	}

	const clearAttachments = () => {
		let updateWithAttachmentsRemoved = update
		updateWithAttachmentsRemoved.attachments = []
		setUpdate(updateWithAttachmentsRemoved)
	}

	return <>
		<Mobile>
			<DefaultMobileBanner />
		</Mobile>
		<Desktop>
			<Banner>
				<BannerInfoText>
					<BannerInfoMainText>{account.name}</BannerInfoMainText>
					<BannerInfoHighlightText>{displayPortalAccType(getPortalAccType(account))}</BannerInfoHighlightText>
				</BannerInfoText>
				<BannerNavLinks parent={LINK_ITEMS().SETTINGS} />
			</Banner>
		</Desktop>
		<PortalContentWrapper>
			{submittingChange
				? <LoadingSpinner />
				: schema && schema.length > 0 && schema.map((group, idx) => {
					return <div key={"group-" + idx}>
						<CardContainer>
							<Card>
								<CardHeader>
									<h3>{group.title}</h3>
								</CardHeader>
								<Row>
									{group.fields.map((field, innerIdx) => {
										const isBoolean = field.type === "boolean";
										let renderable = field.fieldType === "DYNAMIC"
											? fieldToDetailsLinkage(field, update, (fieldName, value) => fetchUniqueness({ fieldName, value, type: "account", ownerId: client.id }), account)
											: renderCore(field)
										return renderable && <Col style={isBoolean ? {display: "flex", alignItems: "center"} : null} key={"field-" + field.title} xs="12" xl="6" className="pr-xl-3">
											{renderable}
										</Col>
									})}
								</Row>
							</Card>
						</CardContainer>
					</div >
				})}
			<SaveChangesBanner openModal={requiresDocs ? openModal : submitChangeRequest} display={changesMade} submittingChange={submittingChange} removeChanges={removeChanges} />
		</PortalContentWrapper>
	</>
}

export default withThreeSkyeGlobal(withAccountInfo(PersonalInformation));
