import PropTypes from "prop-types";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import "./LoggedOutPage.scss";
import LoginPage from "./subpages/LoginPage";
import ForgotPasswordPage from "./subpages/ForgotPasswordPage/ForgotPasswordPage";
import TwoFASetupPage from "./subpages/TwoFAPage";
import CommonFunctions from "../../core-components/functions/CommonFunctions";
import WelcomePage from "./subpages/WelcomePage/WelcomePage";
import GenericWelcome from "./subpages/WelcomePage/GenericWelcome";
import MaintenancePage from "./subpages/MaintenancePage";

class LoggedOutPage extends Component {
	render() {
		const { login } = this.props;
		const sharedProps = { login };
		return (
			<div className={`fade-in ${CommonFunctions.responsiveClassname()}`} id="portal-logged-out-page">
				<Switch>
					<Route exact path="/welcome/:code" render={(props) => <WelcomePage {...props} {...sharedProps} />} />
					<Route exact path="/welcome/" render={(props) => <GenericWelcome {...props} {...sharedProps} />} />
					<Route exact path="/forgot-password" render={(props) => <ForgotPasswordPage {...props} {...sharedProps} />} />
					<Route exact path="/two-factor-auth" render={(props) => <TwoFASetupPage {...props} {...sharedProps} />} />
					<Route exact path="/maintenance" render={(props) => <MaintenancePage {...props} {...sharedProps} />} />
					<Route render={(props) => <LoginPage {...props} {...sharedProps} />} />
				</Switch>
			</div>
		);
	}
}

LoggedOutPage.propTypes = { login: PropTypes.func.isRequired };

export default LoggedOutPage;
