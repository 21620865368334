import React, { Component } from "react";
import PropTypes from "prop-types";
import CommonFunctions from "../functions/CommonFunctions";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import PowerSettingsNew from "@material-ui/icons/PowerSettingsNew";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Launch from "@material-ui/icons/Launch";
import "./Button.scss";
import LoadingSpinner from "../layouts/LoadingSpinner";

class Button extends Component {
	getIcon = label => {
		if (typeof label !== "string") return;
		label = label.replace(/\s/g, "").toLowerCase();
		return (label === "login" && <LockOutlinedIcon />) || (label === "logout" && <PowerSettingsNew />) || (label === "export" && <SaveAlt />) || (label === "generate" && <Launch />);
	};

	render() {
		let { background, colour, children, disabled, fullWidth, outlined, loading, small, secondary, primaryHighlight, compact, generating, style } = this.props;
		let className = "portal-button ";
		className += ` ${CommonFunctions.responsiveClassname()}`;
		if (this.props.className) {
			className += " " + this.props.className;
		}
		if (disabled || loading) className += " disabled";
		else className += " enabled";
		if (background) className += ` ${background}`;
		if (colour) className += ` ${colour}`;
		if (fullWidth) className += " button-full-width";
		if (fullWidth) className += " button-full-width";
		if (outlined) className += " button-outlined";
		if (loading) className += " button-loading";
		if (generating) className += " button-generating";
		if (small) className += " button-size-small";
		if (compact) className += " button-size-compact";
		if (secondary) className += " button-colour-secondary";
		if (primaryHighlight) className += " button-primary-highlight";
			
		const label = children;
		const icon = this.getIcon(label);
		return (
			<button style={style} className={className} onClick={this.props.onClick} disabled={disabled || loading}>
				{this.props.loading ? (
					<div className="button-text-fadein">
						<LoadingSpinner /> Loading
					</div>
				) : this.props.generating ? (
					<div>
						<LoadingSpinner /> <div className="button-label">Generate</div>
					</div>
				) : (
					<div>
						{icon} <div className="button-label">{label}</div>
					</div>
				)}
			</button>
		);
	}
}

Button.propTypes = {
	background: PropTypes.string,
	colour: PropTypes.string,
	onClick: PropTypes.func.isRequired,
};

export default Button;
