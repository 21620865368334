import React, { Component } from "react";

export const HOBSON_THEME = "theme-hobson";
export const THREESKYE_THEME = "theme-3skye";

export const Theme = React.createContext({
	theme: HOBSON_THEME,
});

export function withTheme(WrappedComponent) {
	return class extends Component {
		render() {
			return <Theme.Consumer>{({ theme }) => <WrappedComponent {...{ theme }} {...this.props} />}</Theme.Consumer>;
		}
	};
}
