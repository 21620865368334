import React from "react";
import RangeBar from '../../../../core-components/graphs/RangeBar'

const ProposedPortfolioTableRow = (props) => {
    const { min, max, target, group, assetClassName } = props

    return (
        <tr className="proposed-portfolio-table-row">
            <td className={group ? "" : "asset-group-title-row"}>{assetClassName}</td>
            <td className="">{target}%</td>
            <td>{`${min} - ${max}%`}</td>
            <td><RangeBar
                extent={100}
                value={target}
                min={min}
                max={max}
                colour1={"#208BA5"}
                colour2={"#397D9A"}
                colour3={"rgb(173 193 191 / 24%)"}
                useLogo={true}
                noToolTip={true}
                centreLogoOnPoint={true}
            /></td>
        </tr>
    )
}

export default ProposedPortfolioTableRow