import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { ThreeSkyeWrapper, withThreeSkyeGlobal } from "@threeskye/global";
import CustomerPortalPage from "./layouts/CustomerPortalPage/CustomerPortalPage";
import "./core-components/styles/CoreStyles.scss";
import LoggedOutPage from "./layouts/LoggedOutPage/LoggedOutPage";
import './App.scss';
import { Theme, HOBSON_THEME/*, THREESKYE_THEME*/ } from "./core-components/contexts/ThemeContext";
import AdviserAuth from "./layouts/CustomerPortalPage/AdviserAuth";
import AdviserAuthAccount from "./layouts/CustomerPortalPage/AdviserAuthAccount";
import Modals from "./core-components/modal/Modals";
import { DragDropContext } from "react-dnd";
import HTML5Backend from 'react-dnd-html5-backend'
import { hotkeys } from 'react-keyboard-shortcuts';
import "./core-components/styles/StaticDataOverrides.scss";
import HandleBankVerification from "./layouts/Auxiliary/HandleBankVerification";
import { ModalTypes } from "./core-components/modal/ModalUtils";
// import { loadReCaptcha } from 'react-recaptcha-google';

class App extends Component {
	constructor(props) {
		super(props);
		// this.state = {
			// 	loggedIn: sessionStorage.getItem("3skye.loggedin") === "true",
			// 	theme: HOBSON_THEME
			// };
			this.resize = this.resize.bind(this);
			this.login = this.login.bind(this);
			this.logout = this.logout.bind(this); 
			this.showMessage = this.showMessage.bind(this);

		let portal = this;
        let modals = {
            active: [],
            inactive: [],
            queued: null,

            /**
             * Shows a modal, if necessary minimising the current modal stack to do so.
             * @param {string} type
             * @param {*} data
             */
            show: function(type, data) {
                if (this.active.length > 0) {
                    //TODO check if minimising is valid
                    //for now, only constraint is we can't minimise stacks (too complicated)
                    if (this.active.length > 1)
                        return;

                    if (!this.active[0].type.minimisable)
                        return;

                    //deactivate, without setState
                    let modal = this.active.splice(0, 1);
                    this.inactive.push(modal[0]);
                    this.queued = { type, data };
                } else {
                    this.active.unshift({ type, data });
                }
                portal.setState({modals: this});
                portal.props.remote.get("/users/ping");
            },

            actionQueue: function() {
                if (!this.queued)
                    return;

                this.active.unshift(this.queued);
                this.queued = null;
                portal.setState({modals: this});
            },

            /**
             * Closes the topmost modal, resulting in any stacked modals showing instead
             */
            close: function() {
                this.active.splice(0, 1);
                portal.setState({modals: this});
                portal.props.remote.get("/users/ping");
            },

            /**
             * Closes the entire stack
             */
            clear: function() {
                this.active.length = 0;
                portal.setState({modals: this});
            },

            addInactive: function(type, data) {
                this.inactive.push({ type, data });
                portal.setState({modals: this});
            },

            activate: function(index) {
                let modal = this.inactive.splice(index, 1);
                this.active.unshift(modal[0]);
                portal.setState({modals: this});
            },

            remove: function(index) {
                this.inactive.splice(index, 1);
                portal.setState({modals: this});
            },

            /**
             * Deactivates the current showing modal
             */
            deactivate: function() {
                let modal = this.active.splice(0, 1);
                this.inactive.push(modal[0]);
                portal.setState({modals: this});
            },

            /**
             * Creates a modal record but instead of showing it, immediately adds it to the unfinisheds
             */
            createDeactivated: function(type, data) {
                this.inactive.push({ type, data });
            },

            showing: function() {
                return this.active.length > 0;
            },

            /**
             * Adds a new modal to the stack WITHOUT minimising the existing one
             *
             * Stacked modals MUST share the same initialisationData.  Implementation
             * is responsible for making this work.
             *
             * @param {string} type
             * @param {*} data
             */
            stack: function(type, additionalData) {
                additionalData && Object.assign(this.active[0].data, additionalData);
                this.active.unshift({ type, data: this.active[0].data });
                portal.setState({modals: this});
            }
        };

        this.state = {
            loggedIn: sessionStorage.getItem("3skye.loggedin") === "true",
            theme: HOBSON_THEME,
            showCreateEventModal: false,
            showCreateNoteModal: false,
            showSearch: false,
            showMobileMenu: false,
            searchText: "",
            toaster: {
                toast: this.toast
            },
            toastVisible: false,
            toastMessage: "",
            modals,
            // progress
        };

        this.props.storage.put("portal.modals", modals);
        // this.props.storage.put("portal.progress", progress);

        // this.props.storage.watch("portal.showMobileMenu", val =>
        //     this.setState({ showMobileMenu: val })
        // );
        // this.props.storage.watch("portal.showSearch", val => {
        //     this.setState({ showSearch: val });
        //     if (!val) {
        //         this.props.storage.put("portal.searchTerm", "");
        //     }
        // });
        // this.props.storage.watch("portal.searchTerm", val =>
        //     this.setState({ searchText: val })
        // );
        // this.props.storage.watch("portal.theme", theme=>{
        //     this.setState({ theme });
        // });
    

	}
	
	componentDidMount() {
		window.addEventListener("resize", this.resize);
		// loadReCaptcha();
		this.props.remote.registerLogoutHandler(this.logout);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize);
	}

	resize() {
		this.forceUpdate();
	}

	login() {
		this.setState({ loggedIn: true });
		sessionStorage.setItem("3skye.loggedin", "true");
	}

	logout() {
		sessionStorage.setItem("3skye.loggedin", "false");
		this.setState({ loggedIn: false, loading: true });
	}

	showMessage(message, header, type, onClose) {
		this.props.storage.get("portal.modals").show(ModalTypes.GenericMessageModal, { message, header, type, onClose });
	}

	render() {
		console.log("Rendering at " + window.location.href);
		return (
			<Theme.Provider value={{ theme: this.state.theme }}>
				<div className={this.state.theme}>
					<BrowserRouter>
						<Switch>
							<Route path="/akahu/oauth" render={props => <HandleBankVerification showMessage={this.showMessage}/>} />
							<Route path="/:accountNumber/adviser/:auth/:client" render={props=> <AdviserAuthAccount login={this.login} />} />
							<Route path="/adviser/:auth/:client" render={props=> <AdviserAuth login={this.login} />} />
							<Route render={(props) => (this.state.loggedIn ? <CustomerPortalPage {...props} logout={this.props.storage.logout} /> : <LoggedOutPage {...props} login={this.login} />)} />
						</Switch>
						<Modals modals={this.state.modals} />
					</BrowserRouter>
					{/* Button for playing with the theme toggle */}
					{/* <button className="toggle-theme-button" onClick={() => this.setState({ theme: this.state.theme === HOBSON_THEME ? THREESKYE_THEME : HOBSON_THEME })}>
						TOGGLE THEME
					</button> */}
				</div>
			</Theme.Provider>
		);
	}
}

class Wrapped extends Component {
	render() {
		const With = withThreeSkyeGlobal(DragDropContext(HTML5Backend)(hotkeys(App)));

		return (
			<ThreeSkyeWrapper disableI18n={true}>
				<With />
			</ThreeSkyeWrapper>
		);
	}
}

export default Wrapped;
