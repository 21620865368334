import React, { Component } from "react";
import withRouteChange from "@threeskye/route-change/dist/RouteChange";
import { ModalTypes } from "../modal/ModalUtils";
import { isBefore, parseISO } from "date-fns";

export const NotificationsContext = React.createContext({
	notifications: null,
	onNotficationClick: null,
});

export const NotificationsWrapper = withRouteChange(
	class extends Component {
		state = { notifications: null, notificationsLoading: true };

		constructor(props) {
			super(props);
			this.onNotificationClick = this.onNotificationClick.bind(this);
			this.deleteNotification = this.deleteNotification.bind(this);
			this.getUnreadNotifications = this.getUnreadNotifications.bind(this)
		}

		componentDidMount() {
			this.props.storage.getOrFetch("/modules/crm/client-notifications")
				.then((notifications) => {
					return notifications.sort((notif1, notif2) => {
						let date1 = parseISO(notif1.createdDate)
						let date2 = parseISO(notif2.createdDate)

						if (!date1 && !date2) return 0
						else if (!date1) return -1
						else if (!date2) return 1
						else if (isBefore(date1, date2)) return 1
						else if (isBefore(date2, date1)) return -1
						else return 0
					})
				})
				.then(this.getUnreadNotifications)
			this.props.storage.watch("/modules/crm/client-notifications", this.getUnreadNotifications)
		}

		componentWillUnmount() {
			this.props.storage.unwatch("/modules/crm/client-notifications")
		}

		getUnreadNotifications(notifications) {
			const unreadNotifications = notifications.filter(n => n.clientRead === false)
			this.setState({ notifications, notificationsLoading: false, unreadNotifications })
		}

		onNotificationClick(notification, account) {
			const content = JSON.parse(notification.content)
			this.props.remote.put(`/modules/crm/client-notifications/${notification.id}`)
				.then(() => {
					//set notification as read in state
					let newNotifList = this.state.notifications.map((not) => {
						if (not.id === notification.id) {
							return { ...not, clientRead: true }
						} else {
							return not
						}
					})
					this.setState({ notifications: newNotifList })

					this.props.storage.refresh("/modules/crm/client-notifications").then((resp) => {
						const unreadNotifications = resp.filter(n => n.clientRead === false)
						this.setState({ notifications: resp, unreadNotifications })

					})
					//if link in notification, follow link
					if (content && content.clientPortalLink) {
						const linkArray = content.clientPortalLink.split("/")
						const reviewId = linkArray[linkArray.length - 1]

						this.props.changeRoute(content.clientPortalLink)
						// temporary fix: remove review get endpoint once clientRead is working
						this.props.storage.getOrFetch(`/modules/crm/account/${account.id}/review/${reviewId}`).then(fetchReview => {
							if (fetchReview.status && fetchReview.status !== "Authorised" && !notification.clientRead) {
								console.log("fetchReview", fetchReview)
								this.props.remote.put(`/modules/crm/account/${account.id}/review/${reviewId}/read`).then((resp) => { console.log("read response", resp) })
							}
						})
					} else {
						this.props.storage.get("portal.modals").show(ModalTypes.ViewNotificationModal, { notification, account });
					}

				})
		}

		deleteNotification(notification, event) {
			//Delete it
			event.stopPropagation();
			this.setState({ notifications: this.state.notifications.filter((notificationFromList) => notificationFromList.id != notification.id) })
			this.props.remote.send("delete", `/modules/crm/client-notifications/${notification.id}`)
			// .then((notifications) => {});
		}

		render() {
			const { notifications, notificationsLoading, unreadNotifications } = this.state;
			const { children } = this.props;
			return <NotificationsContext.Provider value={{ notifications, notificationsLoading, unreadNotifications, onNotificationClick: this.onNotificationClick, deleteNotification: this.deleteNotification }}>{children}</NotificationsContext.Provider>;
		}
	}
);

export default function withNotifications(WrappedComponent) {
	return withRouteChange(
		class extends Component {
			render() {
				return (
					<NotificationsContext.Consumer>
						{({ notifications, notificationsLoading, unreadNotifications, onNotificationClick, deleteNotification }) => (
							<WrappedComponent {...this.props} {...{ notifications, notificationsLoading, unreadNotificationCount: unreadNotifications && unreadNotifications.length || 0, onNotificationClick, deleteNotification }} />
						)}
					</NotificationsContext.Consumer>
				);
			}
		}
	);
}
