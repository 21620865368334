import React, { Component } from "react";
import DashboardAdvisers from "./DashboardAdvisers";
import DashboardNotifications from "./DashboardNotifications";
import Desktop from "../../../../core-components/layouts/Desktop";
import PortalContentWrapper from "../../PortalContentWrapper";
import Banner from "../../Banner";
import BannerInfoText, { BannerInfoIntroText } from "../../../../core-components/misc/BannerInfoText";
import Mobile from "../../../../core-components/layouts/Mobile";
import DefaultMobileBanner from "../../DefaultMobileBanner";
import DashboardPortfolios from "./DashboardPortfolios";
import DashboardDisclaimer from "./DashboardDisclaimer";

class Dashboard extends Component {
	render() {
		return (
			<>
				<Mobile>
					<DefaultMobileBanner />
				</Mobile>
				<Desktop>
					<Banner>
						<BannerInfoText>
							<BannerInfoIntroText>Welcome to your dashboard</BannerInfoIntroText>
						</BannerInfoText>
					</Banner>
				</Desktop>
				<PortalContentWrapper hideBackToTop>
					<div className="card-row">
						<DashboardPortfolios />
						<DashboardAdvisers />
						<Desktop>
							<DashboardNotifications />
						</Desktop>
						<DashboardDisclaimer />
					</div>
				</PortalContentWrapper>
			</>
		);
	}
}
export default Dashboard;
