import React, { Component } from "react";
import Formatter from "../functions/Formatter";
import ToolTip from "../layouts/ToolTip";
import HobsonLogoStrategic from "../../layouts/CustomerPortalPage/subpages/Reviews/HobsonLogoStrategic.png"
import './RangeBar.scss';

class RangeBar extends Component {

    render() {
        let { extent, value, min, max, navigator, defensiveVsGrowth, noToolTip, useLogo, colour1, colour2, colour3, centreLogoOnPoint } = this.props;

        let bar = (value / extent * 100) + "%";
        let vMin = (min / extent * 100) + "%";
        let vWidth = ((max - min) / extent * 100) + "%";

        let className = "range-bar-container";
        if (vWidth != "0%" && (value < min || value > max || min > max))
            className += " warning";

        return (noToolTip ?
            <div className={className} style={{ backgroundColor: colour3 ? colour3 : null, cursor: "default" }}>
                <div className="range-bar-variance" style={{ backgroundColor: colour1 || null, marginLeft: vMin, width: vWidth, position: 'absolute' }}></div>
                {useLogo ?
                    <img src={HobsonLogoStrategic} className="hobson-logo-range-bar" style={{ left:  centreLogoOnPoint ? `calc(${value}% - 10px)` : `${value}%`, position: "relative", cursor: "default" }} /> :
                    <div className="range-bar" style={{ backgroundColor: colour2 || null, left:  centreLogoOnPoint ? `calc(${value}% - 1.5px` : `${value}%`, position: "relative" }} />
                }
            </div> :
            <div className={className}>
                <ToolTip disabled={defensiveVsGrowth} medium title={Formatter.asPercentage(value / extent * 100, 1)} outerStyle={{ marginLeft: (defensiveVsGrowth ? 0 : bar), width: (defensiveVsGrowth ? bar : "auto"), zIndex: "100" }}>
                    <div className="range-bar" style={{ width: (defensiveVsGrowth ? "100%" : "4px") }} />
                </ToolTip>
                {!navigator && (
                    <ToolTip disabled={defensiveVsGrowth} medium title={Formatter.asPercentage(min / extent * 100, 1) + " - " + Formatter.asPercentage(max / extent * 100, 1)} className="range-bar-tooltip" outerStyle={{ marginLeft: vMin, width: vWidth, zIndex: "99" }}>
                        <div className="range-bar-variance" />
                    </ToolTip>
                )}
            </div>
        )
    }
}

export default RangeBar;