import { withRemote } from "@threeskye/global";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Button from "../../../core-components/inputs/Button";
import Input from "../../../core-components/inputs/Input";
import VerticalSpacer from "../../../core-components/layouts/VerticalSpacer";
// import PostLogin from "./PostLogin";
import { withCookies } from "react-cookie";
import TwoFAPage from "./TwoFAPage";
import FormErrorMessage from "../../../core-components/layouts/FormErrorMessage";
import CenteredLogo from "./CenteredLogo";
import "./LoginPage.scss";
import Loading from "../../../core-components/layouts/Loading";
import LoginViewSelect from "./LoginViewSelect";

const LoginPage = (props) => {
const { source, showPostLogin, remote, login, cookies } = props;
	const [email, setEmail] = useState(props.email || "")
	const [password, setPassword] = useState(props.password || "")
	const [loggingIn, setLoggingIn] = useState(false)
	const [hasFailed, setHasFailed] = useState(false)
	const [show2fa, setShow2fa] = useState(false)
	const [postLoginCompleted, setPostLoginCompleted] = useState(false)
	const [loading, setLoading] = useState(false)
	const [loggingInOnMount, setLoggingInOnMount] = useState(!!showPostLogin)
	const [message, setMessage] = useState("")
	const [twoFAKey, setTwoFAKey] = useState(null)
	const [twofamessage, setTwofamessage] = useState(null)

	useEffect(() => {
		if (loggingInOnMount) {
			onLogin()
		} 
	}, [])

	const onLogin = () => {
		setHasFailed(false)
		setLoggingIn(true)
		const data = { username: email, password: password, source: source };
		const twofa = has2fa();
		if (twofa) {
			data.twoFAToken = twofa;
		}
		remote.post("/um/hwp/login", data).then((response) => {
			if (response.success) {
				setLoading(false)
				setLoggingInOnMount(false)
				login()
			} else if (response.message === "2fa-failure") {
				setLoading(false)
				setLoggingInOnMount(false)
				setShow2fa(true)
				setTwoFAKey(response.data.key)
			} else if (response.message === "account-locked") {
				console.log("account is locked")
				setLoading(false)
				setLoggingIn(false)
				setLoggingInOnMount(false)
				setShow2fa(false)
				setMessage("This account is locked, please contact your Hobson Wealth adviser.")
			} else {
				console.log("generic password fail")
				setLoading(false)
				setLoggingInOnMount(false)
				setLoggingIn(false)
				setMessage("Could not log in. Please try again.")
			}
		});
	}

	const has2fa = () => {
		let username = email.replace("@", "#");
		let cookie = cookies.get("3skye.login." + username);
		if (typeof cookie === "undefined") {
			cookie = cookies.get("hwp.login." + username);
		}
		if (typeof cookie === "undefined") {
			return false;
		}

		return cookie;
	}

	const check2fa = (code) => {
		const data = {
			username: email,
			code,
			key: twoFAKey,
		};

		remote.post("/um/validate-credentials-2fa", data).then(
			(resp) => {
				if (resp.status === "valid") {
					const cookieValue = has2fa();//resp.cookie;

					let username = email.replace("@", "#");
					const oneYear = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
					cookies.set("hwp.login." + username, cookieValue, { path: "/", expires: oneYear, secure: true });
					const data2 = {
						username: email,
						password: password,
						twoFAToken: cookieValue,
						source: source
					};

					remote.post("/um/hwp/login", data2, { rawResponse: true }).then(
						(x) => {
							login();
						},
						(x) => {
							setHasFailed(true)
							setShow2fa(false)
							setMessage("We couldn't log you in ... please try again")
						}
					);
				}
			},
			(x) => {
				setTwofamessage("Oops, that code wasn't right.  Please try again")
			}
		);
	}



	if (show2fa) return <TwoFAPage message={twofamessage} onSubmit={check2fa} /*setup={twofaSetup} cancelSetup={this.cancelSetup}*/ />;
	const loginOnKeyPress = (e) => e.key === "Enter" && onLogin();

	// Dummy variable for displaying the view select. 
	const viewSelect = false;
	return (
		<CenteredLogo dividerHeight={viewSelect && "32px"}>
			{loggingInOnMount ? (
				<Loading centered size={80} />
			) : viewSelect ? (
				<LoginViewSelect />
			) : (
				<div id="login-page">
					<Input label="email" name="email" value={email} placeholder="me@example.com" onChange={(email) => setEmail(email) } onKeyPress={loginOnKeyPress} />
					<VerticalSpacer size="14px" />
					<Input label="password" name="password" value={password} placeholder="Password" onChange={(password) => setPassword(password) } onKeyPress={loginOnKeyPress} />
					{message && console.log("Message in render code!", message)}
					{message && <FormErrorMessage ><div>{message}</div></FormErrorMessage>}
					{}
					<VerticalSpacer size="14px" />
					<Button loading={loggingIn} onClick={onLogin} disabled={loggingIn}>
						log in
					</Button>
					<VerticalSpacer size="14px" />
					<div className="d-flex justify-content-between">
						<Link to="/forgot-password/">Forgot Password?</Link>
						<Link to="/welcome">First Time Login</Link>
					</div>
					<div className="access-message">
						<p>If you’d like access to this portal, please call <a href="https://www.hobsonwealth.co.nz/team/" target="_blank" rel="noopener noreferrer">your adviser</a></p>
						<p>By accessing our client portal you agree to our  <a href="https://www.hobsonwealth.co.nz/wp-content/uploads/2021/03/Hobson-Terms-of-Use-0221-2.pdf" target="_blank" rel="noopener noreferrer">Terms of Use</a></p>
					</div>
				</div>
			)}
		</CenteredLogo>
	);
}


LoginPage.propTypes = { login: PropTypes.func.isRequired };

export default withCookies(withRemote(LoginPage));
