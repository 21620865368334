import React, { useState, useEffect, useRef } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer, ReferenceArea } from "recharts";
import { isLastDayOfMonth } from "date-fns/esm";


const ReviewsLineChart = (props) => {
    const { 
        chartData, ticks, areas, defaultStopColour, refAreaFill, 
        isPerformance, up, down, gradientOffset, performance 
    } = props

    const { yearColour } = props || "#fff";
    const interval = 0
    const [yearTicks, setYearTicks] = useState([]);
    const chartRef = useRef()
    const withBackground = true


    useEffect(() => {
        let tempYearTicks = [];
        if (!chartData || chartData.length === 0) {
            return;
        }
        for (let i = 0; i < chartData.length - 1; i++) {
            if (i === 0) {
                tempYearTicks.push(chartData[i].date);
                continue;
            }
            let date = new Date(chartData[i].date);
            const day = date.getDate();
            const month = date.getMonth() + 1;

            if (!performance && day === 1 && month === 1) {
                tempYearTicks.push(chartData[i].date);
                continue;
            } else if (performance && day === 31 && month === 12) {
                tempYearTicks.push(chartData[i].date);
                continue;
            } 
        }
        tempYearTicks.push(chartData[chartData.length - 1].date);

        //we now have first entry, ... every 1st Jan ... , last entry.
        //These will have the bars.  We need to find midpoints between them.
        const allYearTicks = insertMidpoints(tempYearTicks)
        setYearTicks(allYearTicks)

    }, [chartData])


    function insertMidpoints(timestamps) {
        const result = [];
        for (let i = 0; i < timestamps.length - 1; i++) {
            result.push(timestamps[i]);
            result.push(Math.floor((timestamps[i] + timestamps[i + 1]) / 2));
        }
        result.push(timestamps[timestamps.length - 1]);
        return result;
    }

    function formatShortMonth(val) {
        let date = new Date(val);
        switch (date.getMonth()) {
            case 0:
                if (isLastDayOfMonth(date)) return "Feb"
                else return "Jan";
            case 1:
                if (isLastDayOfMonth(date)) return "Mar"
                else return "Feb";
            case 2:
                if (isLastDayOfMonth(date)) return "Apr"
                else return "Mar";
            case 3:
                if (isLastDayOfMonth(date)) return "May"
                else return "Apr";
            case 4:
                if (isLastDayOfMonth(date)) return "Jun"
                else return "May";
            case 5:
                if (isLastDayOfMonth(date)) return "Jul"
                else return "Jun";
            case 6:
                if (isLastDayOfMonth(date)) return "Aug"
                else return "Jul";
            case 7:
                if (isLastDayOfMonth(date)) return "Sep"
                else return "Aug";
            case 8:
                if (isLastDayOfMonth(date)) return "Oct"
                else return "Sep";
            case 9:
                if (isLastDayOfMonth(date)) return "Nov"
                else return "Oct";
            case 10:
                if (isLastDayOfMonth(date)) return "Dec"
                else return "Nov";
            case 11:
                if (isLastDayOfMonth(date)) return "Jan"
                else return "Dec";
            default:
                break;
        }
        return null;
    }

    function formatTick(val) {
        if (val === 0 || val === "0") return val;

        let k = Math.abs(val) > 1000;
        if (k) {
            val = val / 1000;
        }
        val = val + "";
        let neg = val.startsWith("-");
        if (neg) val = val.substring(1);

        return (neg ? "-" : isPerformance ? "+" : "") + "$" + val + (k ? "k" : "");
    }

    function getStops() {
        let stops = [];
        if (up) {
            stops.push({
                offset: 0,
                opacity: 0.3,
            });
            stops.push({
                offset: 80,
                opacity: 0,
            });
        } else if (down) {
            stops.push({
                offset: 20,
                opacity: 0,
            });
            stops.push({
                offset: 100,
                opacity: 0.3,
            });
        } else {
            stops.push({
                offset: 0,
                colour: "#ff0000",
                opacity: 0.3,
            });
            stops.push({
                offset: gradientOffset * 80,
                opacity: 0,
            });
            stops.push({
                offset: 100 - (1 - gradientOffset) * 80,
                opacity: 0,
            });
            stops.push({
                offset: 100,
                opacity: 0.3,
            });
        }
        return stops;
    }

    const stops = getStops();

    function customizedYearTick(tickProps) {
        const { x, y, payload, index } = tickProps;
        const { value } = payload;
        const date = new Date(value);
        const year = date.getFullYear();
        const secondToLastDate = new Date(yearTicks[yearTicks.length - 2])

        if (performance && date.getMonth() === 11 && date.getDate() === 31) {
            return (<g><text
                x={x}
                y={y}
                fontSize={13}
                textAnchor="middle"
                fill={yearColour}
                fillOpacity={0.6}
            >
                |
            </text></g>
            );
        }
        if (!performance && date.getMonth() === 0 && date.getDate() === 1) {
            return (<g><text
                x={x}
                y={y}
                fontSize={13}
                textAnchor="middle"
                fill={yearColour}
                fillOpacity={0.6}
            >
                |
            </text></g>
            );
        }
        if (value === yearTicks[0]) {
            return (<g><text
                x={x}
                y={y}
                fontSize={13}
                textAnchor="middle"
                fill={yearColour}
                fillOpacity={0.6}
            >
                |
            </text></g>
            );
        }
        if (value === yearTicks[yearTicks.length - 1]) {
            // avoid having two consecutive "|" year ticks
            const consecutiveSymbolAsTick = performance && secondToLastDate.getDate() === 31 && secondToLastDate.getMonth() === 11
            return (<g><text
                x={x}
                y={y}
                fontSize={12}
                textAnchor="middle"
                fill={yearColour}
                fillOpacity={0.6}
            >
                {consecutiveSymbolAsTick ? year : "|"}
            </text></g>
            );
        }

        return (<g><text
            x={x}
            y={y}
            fontSize={12}
            textAnchor="middle"
            fill={yearColour}
            fillOpacity={0.6}
        >
            {year}
        </text></g>
        );
    }

    function customValueTick({ x, y, payload }) {
        const { value } = payload;
        const formattedValue = formatTick(value)

        return (<g><text
            x={x}
            y={y}
            fontSize={12}
            textAnchor="middle"
            fill={yearColour}
            fillOpacity={0.6}
            dx={-25}
            dy={5}
        >
            {formattedValue}
        </text></g>
        );

    }

    const CustomMonthTick = ({ x, y, payload }) => {
		const { value } = payload;
		const isLastTick = value === (ticks[ticks.length - 1]);
		const offset = isLastTick ? -10 : 0;
		const formattedValue = formatShortMonth(value)

		return (<g><text
			x={x + offset}
			y={y}
			fontSize={12}
			textAnchor="middle"
			fill={yearColour}
			dy="0.71em"
		>
			{formattedValue}
		</text></g>
		);
	};


    return (
        <div ref={chartRef}>
            <ResponsiveContainer width="99%" height={280}>
                <LineChart height={250} data={chartData}>
                    <defs>
                        <linearGradient id="colorUv" x1="0" x2="0" y1="0" y2="1">
                            {stops.map((stop, idx) => {
                                return <stop key={idx} offset={stop.offset + "%"} stopColor={defaultStopColour} stopOpacity={stop.opacity} />;
                            })}
                        </linearGradient>
                    </defs>
                    {/* Month */}
                    <XAxis
                        interval={interval}
                        dataKey="date"
                        tickLine={false}
                        ticks={ticks}
                        type="number"
                        domain={["dataMin", "dataMax"]}
                        tickFormatter={formatShortMonth}
                        tick={CustomMonthTick}
                    />
                    {/* Year */}
                    <XAxis
                        interval={0}
                        dataKey="date"
                        axisLine={false}
                        type="number"
                        domain={["dataMin", "dataMax"]}
                        tickLine={false}
                        tick={customizedYearTick}
                        xAxisId={1}
                        ticks={yearTicks}
                    />
                    <YAxis tick={customValueTick} tickFormatter={formatTick} axisLine={false} tickLine={false} domain={isPerformance ? undefined : ["auto", "auto"]} />
                    {withBackground && chartData && chartData[0] && <ReferenceArea key="background1" x1={chartData[0].date} className="line-chart-background reviews-1" isFront={false} />}
                    {withBackground && chartData && chartData[0] && <ReferenceArea key="background2" x2={chartData[0].date} className="line-chart-background reviews-2" isFront={false} />}
                    {areas && areas.map((area, idx) => (
                        <ReferenceArea key={idx} x1={area[0]} x2={area[1]} fill={refAreaFill} isFront={false} className="reviews-1" />
                    ))}
                    <CartesianGrid vertical={false} />
                    <>
                        <Line className="line-1" type="monotone" dataKey="value" stroke="#00bfeb" strokeWidth={3} fillOpacity={1} fill="url(#colorUv)" dot={false} activeDot={<circle className="recharts-dot dot-1" />} />
                        <Line className="line-2" type="monotone" dataKey="value2" stroke="#00bfeb" strokeWidth={3} fillOpacity={1} fill="url(#colorUv)" dot={false} activeDot={<circle className="recharts-dot dot-2" />} />
                    </>

                </LineChart>
            </ResponsiveContainer>
        </div>

    );
}

export default ReviewsLineChart;
