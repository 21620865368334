import React, { Component } from "react";
import moment from "moment";
import Formatter from "../functions/Formatter";
import { withAccountInfo } from "../contexts/AccountsContext";
import withRouteChange from "@threeskye/route-change";
import withChartDurationSelected from "@threeskye/core-components/dist/components/Charts/withChartDurationSelected";
import CommonFunctions from "../functions/CommonFunctions";
import { subYears } from 'date-fns'

export default function (WrappedComponent) {
	return withChartDurationSelected(
		withAccountInfo(
			withRouteChange(
				class extends Component {
					timeFrames = [
						{ label: "1m", value: 31 },
						{ label: "3m", value: 93 },
						{ label: "6m", value: 186 },
						{ label: "1y", value: 365 },
						{ label: "2y", value: 730 },
					];

					constructor(props) {
						super(props);
						this.state = {
							selectedTimeFrameIndex: 4,
							loadingValuations: true,
						};
						this.setSelectedTimeFrameIndex = this.setSelectedTimeFrameIndex.bind(this);
						this.renderTooltip = this.renderTooltip.bind(this);
						this.fetchValuations = this.fetchValuations.bind(this);
					}

					componentDidMount() {
						this.fetchValuations();
					}

					componentDidUpdate(prevProps) {
						if (prevProps.account.id !== this.props.account.id) {
							this.fetchValuations();
						}
					}

					fetchValuations() {
						const { account } = this.props;
						// default to two years of data.
						const cutoffDate = Formatter.dateAsDTO(subYears(new Date(), 2))
						const now = new Date();
						const endDate = Formatter.dateAsDTO(now);
						this.setState({ loadingValuations: true });
						Promise.all([
							this.props.storage.getOrFetch(`/modules/crm/accounts/${account.id}/valuation?cutoffDate=${cutoffDate}&endDate=${endDate}`),
							this.props.storage.getOrFetch(`/modules/crm/accounts/${account.id}/capital-movements?cutoffDate=${cutoffDate}&endDate=${endDate}`),
						]).then(([valuation, contributionsResponse]) => {
							let map = {};
							if (valuation && valuation.length > 0) {
								map = valuation.reduce((vMap, { date, value }) => ({ ...vMap, [date]: { value } }), map);
							}
							if (contributionsResponse && !contributionsResponse.connectionError && contributionsResponse.data && contributionsResponse.data.length > 0) {
								map = contributionsResponse.data.reduce((vMap, { date, value }) => ({ ...vMap, [date]: { ...(vMap[date] || {}), value2: value } }), map);
							}
							valuation = Object.keys(map)
								.map((date) => ({ date: parseInt(moment(date).format("x")), ...map[date] }))
								.sort((a, b) => a.date - b.date);
							// valuation = valuation.map((v) => ({ date: new Date(v.date).getTime(), value: v.value }));
							this.setState({ valuation, loadingValuations: false });
							this.props.durationSelected(this.timeFrames[this.state.selectedTimeFrameIndex].value, valuation, false);
						});
					}

					setSelectedTimeFrameIndex(selectedTimeFrameIndex) {
						this.setState({ selectedTimeFrameIndex });
						this.props.durationSelected(this.timeFrames[selectedTimeFrameIndex].value, this.state.valuation, false);
					}

					renderTooltip(payload) {
						const { isPerformance, startValue } = this.props.withDurationSelectedData;
						if (!payload.active || payload.payload.length === 0) return null;

						let value = (isPerformance ? startValue : 0) + payload.payload[0].payload.value;
						let value2 = payload.payload[0].payload.value2;
						let date = moment(payload.payload[0].payload.date);
						const hasValue1 = !CommonFunctions.isNullOrUndefined(value) && !isNaN(value);
						const hasValue2 = !CommonFunctions.isNullOrUndefined(value2);
						return (
							<div className="valuations-graph-tooltip">
								<div className="valuations-graph-tooltip-label">{date.format("Do MMMM YYYY")}</div>
								{hasValue1 
									? <div className="valuations-graph-tooltip-value">Value: {Formatter.asMoney(value, 2)}</div>
									: <div className="valuations-graph-tooltip-value">No valuation available</div>
								}
								{hasValue2 && <div className="valuations-graph-tooltip-contribution">Contributions: {Formatter.asMoney(value2, 2)}</div>}
							</div>
						);
					}

					render() {
						const { loadingValuations, valuation, selectedTimeFrameIndex } = this.state;
						const { withDurationSelectedData } = this.props;
						return (
							<WrappedComponent
								loadingValuations={loadingValuations}
								selectedTimeFrameIndex={selectedTimeFrameIndex}
								valuation={valuation}
								setSelectedTimeFrameIndex={this.setSelectedTimeFrameIndex}
								timeFrames={this.timeFrames}
								hasValuations={valuation && valuation.length > 0 && Object.keys(withDurationSelectedData).length > 0}
								chartProps={{ ...withDurationSelectedData, renderTooltip: this.renderTooltip, withBackground: true }}
								{...this.props}
							/>
						);
					}
				}
			)
		)
	);
}
