import React, { useState, useEffect } from 'react';
import withRouteChange from '@threeskye/route-change';
import Mobile from '../../../../core-components/layouts/Mobile';
import Desktop from '../../../../core-components/layouts/Desktop';
import DefaultMobileBanner from '../../DefaultMobileBanner';
import Banner from '../../Banner';
import PortalContentWrapper from '../../PortalContentWrapper';
import BannerNavLinks from '../../../../core-components/layouts/BannerNavLinks';
import Card from '../../../../core-components/card/Card';
import CardHeader from '../../../../core-components/card/CardHeader';
import Button from '../../../../core-components/inputs/Button';
import ProfileImage from '../../../../core-components/misc/ProfileImage';
import TooltipIcon from '../../../../core-components/misc/TooltipIcon';
import ReviewNameToken from './ReviewNameToken';
import CommentsPanel from './CommentsPanel';
import BannerInfoText, { BannerInfoHighlightText, BannerInfoMainText, BannerInfoIntroText } from '../../../../core-components/misc/BannerInfoText';
import EditableVoidsExample from './EditableVoidsExample'
import Loading from '../../../../core-components/layouts/Loading'
import { Close, Comment, Fullscreen, Print } from '@material-ui/icons';
import { withAccountInfo, displayPortalAccType } from '../../../../core-components/contexts/AccountsContext';
import { withThreeSkyeGlobal } from '@threeskye/global';
import { LINK_ITEMS } from '../../CustomerPortalPage';
import { ModalTypes } from '../../../../core-components/modal/ModalUtils';
import { format, subMonths } from 'date-fns'
import './ReviewViewPage.scss';
import './AccountReviews.scss';

const ReviewLabelledDetail = ({ label, value }) => {
	return (
		<div className="review-labelled-detail">
			<div className="detail-label sub-header-small">{label}</div>
			<div className="detail-value">{value}</div>
		</div>
	);
}

const SignedTokens = ({ user, signees }) => {
	// console.log("signees", signees)
	return signees && signees.length > 0 && signees.map((signee, index) => {
		return (
			<div className="signature-tokens-container">
				<ReviewNameToken key={index} name={signee.name} date={signee.authorised} required={signee.required}/>
			</div>)
	})
}

const ConfirmBanner = ({ onConfirmClick, onFeedbackClick, placingFeedback, userSignatureRequires }) => {
	return (
		<div className="confirm-banner-outer">
			<div className="confirm-banner-inner">
				<Button onClick={onFeedbackClick} secondary>{placingFeedback ? "Cancel Feedback" : "Place Feedback"}</Button>
				{userSignatureRequires && <Button onClick={onConfirmClick} >Confirm &amp; Sign</Button>}
			</div>
		</div>
	);
}

const ActionIconWrapper = ({ active, children }) => {
	return <div className={`action-icon-wrapper${active ? " icon-active" : ""}`}>{children}</div>
}

const ReviewViewPage = (props) => {
	const { user, account, storage, match, thirdPartyView, portalAccountType } = props
	const reviewNumber = match.params.id
	const [fullscreen, setFullscreen] = useState(false);
	const [commentsOpen, setCommentsOpen] = useState(false);
	const [document, setDocument] = useState(null)
	const [template, setTemplate] = useState(null)
	const [templateLoaded, setTemplateLoaded] = useState(false);
	const [reviewPeriodSelected, setReviewPeriodSelected] = useState(null)
	const [endDate, setEndDate] = useState(null)
	const [startDate, setStartDate] = useState(null)
	const [comments, setComments] = useState(null)
	const [showConfirmBanner, setShowConfirmBanner] = useState(true)
	const [advisersIds, setAdvisersIds] = useState([])
	const [signatures, setSignatures] = useState(null)
	const period = document ? getPeriodFromTheTemplate() : null
	const userSignatureRequires = signatures && signatures.find(person => person.id === user.id && person.required)

	console.log("signatures", signatures, "userSignatureRequires", userSignatureRequires)
	useEffect(() => {
		/* Clients will need a different endpoint to pull the published review: 
		   1. Clients shouldn't be able to see the advisers comments  
		   2. Clients shouldn't be able to edit the review
		   3. Clients comments should have a reply mechanism 
		   4. Publish review needs a signature tracker
		*/
		if (account && account.advisers) {
			const ids = account.advisers.map(adv => adv.id)
			if (ids && ids.length > 0) setAdvisersIds(ids)
		}
		account && reviewNumber && reviewNumber === ":id" && props.changeRoute(`/${account.number}/my-documents/reviews`)

		account && reviewNumber && reviewNumber !== ":id" && storage.getOrFetch(`/modules/crm/account/${account.id}/review/${reviewNumber}`)
			.then(fetchedReview => {
				console.log("fetched review", fetchedReview);
				const extraDataReview = {
					...fetchedReview,
					filename: `Performance Review`,
				}
				setDocument(extraDataReview)
				console.log("extraDataReview", extraDataReview)

				setReviewPeriodSelected(fetchedReview.customData.reviewPeriod)
				setEndDate(fetchedReview.customData.reviewEndDate)
				setStartDate(getNewStartDate(fetchedReview.customData.reviewPeriod, fetchedReview.customData.reviewEndDate))
				setTemplate(fetchedReview.template)
				if (fetchedReview.customData.reviewPeriod) setTemplateLoaded(true)
				setComments(fetchedReview.clientComments)
				setShowConfirmBanner(fetchedReview.status !== "Authorised")
				setSignatures(fetchedReview.customData.signatures)
			})
	}, [reviewNumber, account])

	function openModal() {
		storage.get("portal.modals").show(ModalTypes.ReviewConfirmModal, { document: document, hideConfirmBanner: hideConfirmBanner, userId: user.id, userName: user.fullName });
	}

	function getAdviser() {
		if (document) {
			const commenterIsAdviser = account.advisers.find(adviser => adviser.id === document.creatorId)
			if (commenterIsAdviser) {
				return { ...commenterIsAdviser, initials: commenterIsAdviser.givenName.charAt(0) + commenterIsAdviser.familyName.charAt(0) }
			} else return { initials: "N/A", fullName: "unknown adviser" }
		}
	}

	// we will need a bettter way to get the dates for the period. They should be saved as one of the review's properties 
	function getPeriodFromTheTemplate() {
		if (document) {
			const string1 = document.template && document.template[0].children[3].children[2].children[0].text
			const string2 = document.template && document.template[0].children[3].children[0].children[0].text
			const startDate = string1.split("since")[1]
			const endDate = string2.split(" at")[1]

			return format(new Date(startDate), "dd/MM/yyyy") + " - " + format(new Date(endDate), "dd/MM/yyyy")
		}
	}

	function hideConfirmBanner() {
		setShowConfirmBanner(false)
	}

	function getNewStartDate(period, endDate) {
        const newStartDate = subMonths(new Date(endDate), period)
        return format(newStartDate, "yyyy-MM-dd")
    }


	return (<>
		<Mobile>
			<DefaultMobileBanner />
		</Mobile>
		<Desktop>
			<Banner thirdPartyView={thirdPartyView}>
				<BannerInfoText>
					<BannerInfoMainText>{account.name}</BannerInfoMainText>
					{thirdPartyView ?
						<BannerInfoIntroText>You are now viewing your Third Party accounts</BannerInfoIntroText> :
						<BannerInfoHighlightText>{displayPortalAccType(portalAccountType)}</BannerInfoHighlightText>
					}
				</BannerInfoText>
				<BannerNavLinks parent={LINK_ITEMS().DOCUMENTS} highlightReviews={true} />
			</Banner>
		</Desktop >
		<PortalContentWrapper subcontentIdName={fullscreen ? "review-details" : null} /* subcontentIdName for the BackToTopButton */>
			<div id="review-page-wrapper" className={`${fullscreen ? "view-fullscreen" : ""}${commentsOpen ? " comments-open" : ""}`}>
				<Card id="review-view-card">
					<CardHeader>
						<div className="review-page-header">
							<h4>{document && document.filename}</h4>
							<div className="review-action-icons">
								<TooltipIcon icon={<Print />} label="Print" onClick={() => alert("Print")} />
								<ActionIconWrapper active={fullscreen}>
									<TooltipIcon icon={<Fullscreen />} label="Fullscreen" onClick={() => setFullscreen(!fullscreen)} />
								</ActionIconWrapper>
								<ActionIconWrapper active={commentsOpen}>
									<TooltipIcon icon={<Comment />} label="Comment" onClick={() => setCommentsOpen(!commentsOpen)} />
								</ActionIconWrapper>
								<TooltipIcon icon={<Close />} label="Close" onClick={() => props.changeRoute(`/${account.number}/my-documents/reviews`)} />
							</div>
						</div>
					</CardHeader>
					{/* content */}
					<div className='template-andtemplate-details'>
						<div className='review-details-with-divider'>
							<div className="review-details" id="review-details">
								<ReviewLabelledDetail label="Period" value={templateLoaded ? period : <Loading size={20} />} />
								<hr />
								<ReviewLabelledDetail label="Adviser" value={templateLoaded ? <ProfileImage client={getAdviser()} /> : <Loading size={20} />} />
								<hr />
								<ReviewLabelledDetail label="Signed & Confirmed" value={templateLoaded ? <SignedTokens user={user} signees={signatures} /> : <Loading size={20} />} />
							</div>
							<hr className="divider-hr" />
						</div>
						{/* content */}
						<div className="review-doc-content" style={fullscreen ? null : { marginLeft: -40 }}>
							{account && templateLoaded && signatures && <div className='template'>
								<EditableVoidsExample
									account={account}
									reviewPeriodSelected={reviewPeriodSelected}
									endDate={endDate}
									startDate={startDate}
									template={template}
									readOnly={true}
									review={document}
									signatures={signatures}
								/>
							</div>}
							{account && !templateLoaded && <div className='template'>
								Loading Review ...
							</div>}
						</div>
					</div>
					{document && advisersIds && showConfirmBanner && <ConfirmBanner onConfirmClick={() => openModal()} onFeedbackClick={() => setCommentsOpen(!commentsOpen)} placingFeedback={commentsOpen} userSignatureRequires={userSignatureRequires} />}
				</Card>
				{/* Temporarily showing the advisers comments*/}
				{comments && <CommentsPanel display={commentsOpen} comments={comments} setComments={setComments} account={account} document={document} currentUser={user} advisersIds={advisersIds} />}
			</div>
		</PortalContentWrapper>
	</>
	);
}

export default withThreeSkyeGlobal(withAccountInfo(withRouteChange(ReviewViewPage)));