import React, { useCallback, useMemo } from 'react'
import { createEditor } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'
import { withAccountInfo } from '../../../../core-components/contexts/AccountsContext';
import HobsonLogo from '../../../../core-components/svgs/HobsonLogo';
import reviewCover from './reviewCover.png'
import HobsonLogoStrategic from './HobsonLogoStrategic.png'
import HobsonLogoTactical from './HobsonLogoTactical.png'
import RangeBar from '../../../../core-components/graphs/RangeBar'
import ReviewsPortfolioPerformanceChart from './ReviewsPortfolioPerformanceChart'
import ReviewsPerformanceChart from './ReviewsPerformanceChart';
import ReviewRecommendedTacticalAllocation from './ReviewRecommendedTacticalAllocation'
import ProposedPortfolioTableRow from './ProposedPortfolioTableRow'
// import ReviewTemplateClients from './ReviewTemplateClients'


const EditableVoidsExample = (props: any) => {
    const { account, review, signatures, reviewPeriodSelected, endDate, startDate } = props

    const readOnly = props.readOnly
    const editor = useMemo(() => {
        return withReact(createEditor())
    }, [])

    const DefaultElement = (props: any) => {
        return <p {...props.attributes}>{props.children}</p>
    }

    const Image = (props: any) => {
        // const url = props.element.url.split("/img/Hobson")[1]
        // return <img {...props.attributes} alt=''
        //     className={props.element.class}
        //     width={props.element.width}
        //     height={props.element.height}
        //     src={"/images/" + url} ></img>

        // temporary solutuion 
        const url = props.element.url.split("/img/Hobson/")[1]
        const name = url.split(".")[0]
        const image = name === "reviewCover" ? reviewCover : name === "HobsonLogoStrategic" ? HobsonLogoStrategic : HobsonLogoTactical
        // console.log(url)

        if (name === "logo") return < HobsonLogo width={132} height={39} />
        return <img {...props.attributes} alt=''
            className={props.element.class}
            style={{ paddingBottom: props.element.paddingBottom }}
            width={props.element.width}
            height={props.element.height}
            src={image} ></img>
    }

    const Leaf = (props: any) => {
        let { attributes, children, leaf } = props
        let style = {}
        if (leaf.bold) {
            children = <strong>{children}</strong>
        }

        if (leaf.code) {
            children = <code>{children}</code>
        }

        if (leaf.italic) {
            children = <em>{children}</em>
        }

        if (leaf.underline) {
            children = <u>{children}</u>
        }

        if (leaf.highlight) {
            style = { backgroundColor: "yellow", color: "black" }
        }

        return <span style={style} {...attributes}>{children}</span>
    }

    const renderLeaf = useCallback((props: any) => <Leaf {...props} />, [])

    const renderElement = useCallback((props: any) => {
        const { element, attributes, children, data, navigation } = props
        const style = {
            textAlign: element.align,
            width: element.width,
            marginBottom: element.marginBottom,
            marginTop: element.marginTop,
            paddingLeft: element.paddingLeft,
            paddingBottom: element.paddingBottom,
            paddingTop: element.paddingTop,
            height: element.height
        }

        switch (element.type) {
            case 'heading-three':
                return <h3 style={style} {...attributes}>{children}</h3>
            case 'heading-four':
                return <h4 style={style} {...attributes}>{children}</h4>
            case 'uneditable':
                return <div style={style} contentEditable={false} {...props.attributes}>{props.children}</div>
            case 'paragraph':
                return <p className={props.element.class} style={style} {...props.attributes}>{props.children}</p>
            case 'span':
                return <span className={props.element.class} style={style} {...props.attributes}>{props.children}</span>
            case 'uneditable-span':
                return <span contentEditable={false} className={props.element.class} style={style} {...props.attributes}>{props.children}</span>
            case 'header':
                return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', paddingBottom: '45px' }} {...props.attributes} {...data} {...navigation}>{props.children}</div>
            case 'list-item':
                return (
                    <li style={style} {...props.attributes}>
                        {props.children}
                    </li>
                )
            case 'bulleted-list':
                return (
                    <ul className={props.element.class} style={style} {...props.attributes}>
                        {props.children}
                    </ul>
                )
            case 'numbered-list':
                return (
                    <ol style={{ paddingLeft: 30 }} {...attributes}>
                        {children}
                    </ol>
                )
            case 'horizontal-rule':
                return <hr style={style} id={props.element.id} {...props.attributes} />
            case 'break':
                return <br />
            case 'valuation-graph':
                return <div>
                    {/* <ReviewsPortfolioPerformanceChart
                        account={account && account}
                        reviewPeriodSelected={reviewPeriodSelected && reviewPeriodSelected}
                        endDate={endDate}
                        startDate={startDate}
                    /> */}
                    < ReviewsPerformanceChart
                        account={account && account}
                        reviewPeriodSelected={reviewPeriodSelected}
                        endDate={endDate}
                        startDate={startDate}
                    />
                </div>
            case 'image':
                return <Image {...props} />
            case 'div':
                return <div className={props.element.class} style={style} {...props.attributes}>{props.children}</div>
            case 'heading':
                return (
                    <h1 style={style} {...attributes} id={navigation?.id}>
                        {children}
                    </h1>
                )
            case 'tactical-allocation':
                return <ReviewRecommendedTacticalAllocation selectedSquares={element.selectedSquares} />
            case 'proposed-portfolio-table-row':
                return <ProposedPortfolioTableRow
                    min={element.min}
                    max={element.max}
                    target={element.target}
                    group={element.group}
                    assetClassName={element.assetClassName}
                />
            case 'footer':
                return <footer style={style} {...props.attributes}>{children}</footer>
            case 'table':
                return <table style={style} className={element.class} {...attributes}>{children}</table>
            case 'table-row':
                return <tr className={element.class} {...attributes}>{children}</tr>
            case 'uneditable-table-row':
                return <tr contentEditable={false} className={element.class} style={style} {...attributes}>{children}</tr>
            case 'table-cell':
                return <td className={element.class} style={style} {...attributes}>{children}</td>
            case 'uneditable-table-cell':
                return <td contentEditable={false} className={element.class} style={style} {...attributes}>{children}</td>
            case 'table-head':
                return <thead {...attributes}>{children}</thead>
            case 'table-body':
                return <tbody className={element.class} {...attributes}>{children}</tbody>
            case 'table-header':
                return <th {...attributes} style={style} >{children}</th>
            case 'hobson-slider':
                return <RangeBar
                    extent={100}
                    value={element.value} //{holdings.percentPortfolio}  // Pass these through from the element???
                    min={element.min}
                    max={element.max}
                    colour1={"#208BA5"}
                    colour2={"#397D9A"}
                    colour3={"rgb(173 193 191 / 24%)"}
                    useLogo={true}
                    noToolTip={true}
                    centreLogoOnPoint={true}
                />
            // case 'clients-array':
            //     return <ReviewTemplateClients clients={signatures} />
            case 'table-cell-with-input':
                return <td className={`${element.class} ${readOnly ? "read-only" : ""}`}>
                    {props.children}
                </td>
            case 'review-input':
                return <div
                    placeholder={element.placeholder}
                    className={`${element.class} ${readOnly ? "read-only" : ""}`}
                >{props.children}</div>
            case 'drop-down':
                return <span className={props.element.class} {...props.attributes}>{props.children}</span>
            case "span-input":
                return <span>{element.value}</span>
            default:
                return <DefaultElement {...props} />
        }
    }, [account])

    const value = props.template

    return (
        <Slate editor={editor} value={value}>
            <Editable
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                placeholder="Enter some text..."
                readOnly={readOnly}
            />
        </Slate>
    )
}


export default withAccountInfo(EditableVoidsExample)