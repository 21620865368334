import React, { Component } from 'react';
import Button from './Button';

class ExportButton extends Component {
	render() { 
		const { onClick } = this.props;
		return ( 
			<Button className="export-button" compact secondary onClick={onClick}>export</Button>
		 );
	}
}

export default ExportButton;