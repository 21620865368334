import React, { useState, useEffect } from "react";
import Banner from "../../Banner";
import { LINK_ITEMS } from "../../CustomerPortalPage";
import BannerInfoText, { BannerInfoHighlightText, BannerInfoMainText } from "../../../../core-components/misc/BannerInfoText";
import { getPortalAccType, withAccountInfo, displayPortalAccType } from "../../../../core-components/contexts/AccountsContext";
import { withThreeSkyeGlobal } from "@threeskye/global";
import BannerNavLinks from "../../../../core-components/layouts/BannerNavLinks"
import PortalContentWrapper from "../../PortalContentWrapper";
import Mobile from "../../../../core-components/layouts/Mobile";
import DefaultMobileBanner from "../../DefaultMobileBanner";
import Desktop from "../../../../core-components/layouts/Desktop";
import CardContainer from "../../../../core-components/card/CardContainer";
import Card from "../../../../core-components/card/Card";
import CardHeader from "../../../../core-components/card/CardHeader";
import { Col, Row } from "reactstrap";
import { ModalTypes } from "../../../../core-components/modal/ModalUtils";
import DetailsLinkage from "./DetailsLinkage";
import RepeatableLinkage from "./RepeatableLinkage";
import NameAndId from "@threeskye/core-components/dist/components/ClientAccountDetails/Fields/NameAndId/NameAndId";
import SinglePhoneField from '@threeskye/core-components/dist/components/ClientAccountDetails/Fields/SinglePhoneField/SinglePhoneField'
import { serviceLevels, sectionFor, validateEmail } from "../../../../core-components/functions/DetailPageUtils";
import _ from "lodash";
import { SingleItem, SingleNumberEdit, SingleLocationField, SingleEmailField, SingleDropDownEdit, SingleDate, SingleCheckbox, SinglePlainTextField, BooleanAt, MonthEndField, SingleCountryField, MultiCountryField, ListOfFields, UniqueItem, UnstructuredButtonField, MultiField, OngoingInvestmentEdit, AdviserView, RemoteIdsView, TaxNumberField } from "@threeskye/core-components/dist/components/ClientAccountDetails/Fields/index"
import SaveChangesBanner from "./SaveChangesBanner";
import {taxValidator} from "@threeskye/core-components/dist/components/ClientAccountDetails/Dependencies/Errors/NZTaxValidator"
import BankAccountEditableComponent from "./BankAccountEditableComponent";

import BankAccounts from "@threeskye/core-components/dist/components/ClientAccountDetails/Fields/AkahuBankAccount/BankAccounts"
import BankAccRepeatableChange from "@threeskye/core-components/dist/components/ClientAccountDetails/Fields/SingleBankAccount/BankAccRepeatableChange"
import "./AccountInformation.scss";
import "../../../../core-components/inputs/SplitFieldInput.scss";
import { AddCircle } from "@material-ui/icons";
import ToolTip from "../../../../core-components/layouts/ToolTip";
import {Info} from "@material-ui/icons"

const AccountInformation = (props) => {
	const { accounts, storage, remote } = props

	const [account, setAccount] = useState({})
	const [accountFields, setAccountFields] = useState({
		addresses: [],
		bankAccounts: [],
		debitAuthorities: [],
		creditApprovals: [],
		phone: []
	})
	const [pending, setPending] = useState({})
	const [update, setUpdate] = useState({
		account: {
			addresses: [],
			interests: [],
			dependants: [],
			phone: [],
			email: [],
			taxNumbers: [],
			regularDeposits: [],
			regularWithdrawals: [],
			bankAccounts: [],
			debitAuthorities: [],
			creditApprovals: [],
		},
		accountPeripherals: {
			associatedClients: {},
		},
		unstructured: {},
		attachments: [],
		comments: "",
	})
	const [updateList, setUpdateList] = useState([])
	const [updateItems, setUpdateItems] = useState([])
	// const [changes, setChanges] = useState(null)
	const [detailsUpdateRequirements, setDetailsUpdateRequirements] = useState(null)
	const [fees, setFees] = useState([])
	const [rules, setRules] = useState([])
	const [errors, setErrors] = useState([])
	const [formulae, setFormulae] = useState([])
	const [schema, setSchema] = useState([])
	const [unstructured, setUnstructured] = useState({})
	// const stateAssembled = {account: accountFields, pending, update, updateList, updateItems, detailsUpdateRequirements, fees, rules, errors, formulae, schema, unstructured}
	const [submittingChange, setSubmittingChange] = useState(false)
	const [requirements, setRequirements] = useState(null);
	const [bankUrl, setBankUrl] = useState("");
	const [revertKey, setRevertKey] = useState(1);
	const [requiresDocs, setRequiresDocs] = useState(false)
	const [fieldsValid, setFieldsValid] = useState({})

	const generalIgnorableKeys = ["id"];
	const ignorableKeysByLabel = {
		"account.addresses": ["longitude", "latitude"],
		"clientSummary.addresses": ["longitude", "latitude"],
		"nonPersonClient.addresses": ["longitude", "latitude"],
		"account.bankAccounts": ["international", "country.key"],
		"accountPeripherals.debitAuthorities": ["type"],
		"accountPeripherals.creditApprovals": ["type"],
		"bankAccount.country": ["key"]
	}
	const getFriendlyChangeName = (updateItem) => {
		let { change } = updateItem;
		if (change.endsWith(".update")) {
			change = change.slice(0, -7);
		}
		const stripped = change;

		const bits = change.split(".");
		if (bits.length === 3) {
			change = bits[0] + "." + bits[1];
		}
		const filter = rule => rule.field === change && (bits.length === 3 ? rule.action === bits[2] : true);
		const rule = rules.filter(filter);

		const fieldName = (change = rule && rule[0] && rule[0].description ? rule[0].description : stripped);
		return { ...updateItem, fieldName };
	}

	const updateChanges = updateItems.map(getFriendlyChangeName)
	const changesMade =
		updateChanges &&
		updateChanges.length > 0 &&
		updateChanges.reduce((hasChanges, change) => hasChanges || (change.isRepeatable ? Object.keys(change.difference) : change.difference).length > 0, false);

	const getUnstructuredRepeatableValues = (field) => {
		const innerScopeUpdate = update.unstructured;

		const lookup = field.field.startsWith('unstructured') ? field.field.substring("unstructured.".length) : field.field;

		// const unstructured = unstructured || "{}");
		return {
			updates: (innerScopeUpdate && innerScopeUpdate[lookup]) || [],
			originals: (unstructured && unstructured[lookup]) || []
		}
	}

	const showUnstructuredValue = (field) => {
		if (!update || !update.unstructured) {
			return "";
		}
		//TODO currently only does strings
		const innerScopeUpdate = update.unstructured;

		const lookup = field.field.startsWith('unstructured') ? field.field.substring("unstructured.".length) : field.field;
		if (innerScopeUpdate[lookup] !== undefined) {
			//ensure view stays empty if user clears (assumes component never sets value to undefined)
			return innerScopeUpdate[lookup];
		}

		if (unstructured && unstructured[lookup]) {
			return unstructured[lookup];
		}
		return "";
	}

	const isPending = (fields) => {
		if (Array.isArray(fields)) {
			//handle multiple
			const found = fields.reduce((prev, curr) => (isPending(curr) || prev), false);
			return found;
		}
		const innerScopePending = pending.raw;
		if (!innerScopePending) {
			return false;
		}
		const found = Object.keys(innerScopePending).find(key => key === fields) || Object.keys(innerScopePending).find(key => key === "unstructured." + fields);
		return found;
	}

	const updateUnstructuredField = (field, value, DisplayComponent) => {
		if (!field.field.startsWith("unstructured")) {
			field.field = "unstructured." + field.field;
		}
		updateChangeList(field.field, "update", value, DisplayComponent);

		let split = field.field.split(".");
		// eslint-disable-next-line
		split.slice(0, split.length - 1).reduce((parent, key) => parent[key], update)[split[split.length - 1]] = value;

		setUpdate(update);
	}

	const getActual = (test) => {
		if (test.startsWith("account.") || test.startsWith("client.") || test.startsWith("nonperson.")) {
			//Core field
			return update[test] || test
		} else {
			//Unstructured field
			return showUnstructuredValue({ field: 'unstructured.' + test });
		}
	}

	const fieldToDetailsLinkage = (field, fetchUniqueness, targetEntity) => {
		let values, nameForUpdates;
		let validatorFunc = field.validation;

		if (validatorFunc && validatorFunc.startsWith("hideIf")) {
			const wholeFormula = validatorFunc.substring(7, validatorFunc.length - 1);
			const clauses = wholeFormula.split(/&/);
			for (var j = 0; j < clauses.length; j++) {
				const formula = clauses[j];
				if (formula.startsWith("!")) {
					if (formula.startsWith("!!")) {
						const test = formula.substring(2);
						const actual = getActual.call(this, test);
						if (!!actual)
							return null;
					} else {
						const test = formula.substring(1);
						const actual = getActual.call(this, test);
						if (!actual)
							return null;
					}
				} else {
					let equals = false, notEquals = false;
					let operatorIndex = formula.indexOf('==');
					if (operatorIndex > 0) {
						equals = true;
					} else {
						operatorIndex = formula.indexOf('!=');
						if (operatorIndex > 0) {
							notEquals = true;
						}
					}
					if (equals || notEquals) {
						const test = formula.substring(0, operatorIndex);
						const value = formula.substring(operatorIndex + 2);

						let actual = getActual.call(this, test);

						//TODO not safe 
						if (actual != null && typeof actual === "object") {
							actual = actual.name;
						}

						if (equals) {
							if (actual === value || String(actual) === value) {
								return null;
							}
						} else if (notEquals) {
							if (actual !== value) {
								return null;
							}
						}
					}
				}
			}
		}

		const editable = field.currentRules?.find(r => r.action == null)?.editable;
		
		switch (field.type) {
			case "string":
				return (<DetailsLinkage
					editable={editable}
					label={field.title}
					value={showUnstructuredValue(field)}
					key={"unstructured_" + field.title}
					type="text"
					view={<SingleItem.View />}
					edit={<SingleItem.Edit />}
					pending={isPending(field.field)}
					onChange={val => { updateUnstructuredField(field, val, <SingleItem.Change label={field.title} />); }}
				/>);
			case "dropdown":
				return (<DetailsLinkage
					editable={editable}
					label={field.title}
					value={showUnstructuredValue(field)}
					key={"unstructured_" + field.title}
					type="dropdown"
					options={field.options}
					view={<SingleItem.View />}
					edit={<SingleItem.Edit />}
					pending={isPending(field.field)}
					onChange={val => { updateUnstructuredField(field, val, <SingleItem.Change label={field.title} />); }}
					revertKey={revertKey}
				/>
				);
			case "date":
				return (<DetailsLinkage
					editable={editable}
					label={field.title}
					value={showUnstructuredValue(field)}
					key={"unstructured_" + field.title}
					type="dropdown"
					options={field.options}
					view={<SingleDate.View />}
					edit={<SingleDate.Edit />}
					pending={isPending(field.field)}
					onChange={val => { updateUnstructuredField(field, val, <SingleDate.Change label={field.title} />); }}
					revertKey={revertKey}
				/>
				);
			case "boolean":
				return (<DetailsLinkage
					editable={editable}
					label={field.title}
					omitLabel={true}
					value={showUnstructuredValue(field)}
					key={"unstructured_" + field.title}
					type="dropdown"
					options={field.options}
					view={<SingleCheckbox.View checkbox={<i className="material-icons">check_box</i>} check_box_outline_blank={<i className="material-icons">check_box_outline_blank</i>} />}
					edit={<SingleCheckbox.Edit checkbox={<i className="material-icons">check_box</i>} check_box_outline_blank={<i className="material-icons">check_box_outline_blank</i>} />}
					pending={isPending(field.field)}
					onChange={val => { updateUnstructuredField(field, val, <SingleCheckbox.Change label={field.title} checkbox={<i className="material-icons">check_box</i>} check_box_outline_blank={<i className="material-icons">check_box_outline_blank</i>} />); }}
					className="checkbox-field-outer"
				/>
				);
			case "booleanAt":
				return (<DetailsLinkage
					editable={editable}
					label={field.title}
					value={showUnstructuredValue(field)}
					key={"unstructured_" + field.title}
					type="dropdown"
					options={field.options}
					view={<BooleanAt.View />}
					edit={<BooleanAt.Edit clearLabel={"Unset"} />}
					pending={isPending(field.field)}
					onChange={val => { updateUnstructuredField(field, val, <BooleanAt.Change label={field.title} />); }}
					className="checkbox-date-field-outer"
				/>
				);
			case "integer":
			case "double":
			case "number":
				return (<DetailsLinkage
					editable={editable}
					label={field.title}
					value={showUnstructuredValue(field)}
					key={"unstructured_" + field.title}
					view={<SingleItem.View />}
					edit={<SingleNumberEdit />}
					pending={isPending(field.field)}
					onChange={val => { updateUnstructuredField(field, val, <SingleItem.Change label={field.title} />); }}
				/>
				);
			case "list":
				return (<DetailsLinkage
					editable={editable}
					label={field.title}
					value={showUnstructuredValue(field)}
					key={"unstructured_" + field.title}
					type="dropdown"
					options={field.options}
					view={<SingleItem.View />}
					edit={<SingleDropDownEdit />}
					pending={isPending(field.field)}
					onChange={val => { updateUnstructuredField(field, val, <SingleItem.Change label={field.title} />); }}
					revertKey={revertKey}
				/>
				);
			case "monthEnd":
				return (<DetailsLinkage
					editable={editable}
					label={field.title}
					value={showUnstructuredValue(field)}
					key={"unstructured_" + field.title}
					type="monthEnd"
					view={<MonthEndField.View />}
					edit={<MonthEndField.Edit />}
					pending={isPending(field.field)}
					onChange={val => { updateUnstructuredField(field, val, <MonthEndField.Change label={field.title} />); }}
				/>
				);
			case "address":
				values = getUnstructuredRepeatableValues(field);
				nameForUpdates = "unstructured." + field.field;
				if (!update.unstructured[field.field])
					update.unstructured[field.field] = [];

				return <RepeatableLinkage
					editable={editable}
					key={"unstructured_" + field.title}
					idPrefix="address-"
					data={showRepeatableValues(values.updates, values.originals) || []}
					update={update.unstructured[field.field]}
					onAdd={() => addRepeatable(nameForUpdates, nameForUpdates, { name: "Address", content: { fullAddress: "" } }, <SingleLocationField.Change typeLabel={field.title} />)}
					onDelete={(data, update, index) => deleteRepeatable(nameForUpdates, nameForUpdates, data[index], <SingleLocationField.Change typeLabel={field.title} />)}
					label={(data, update, index) => field.title + ": " + data[index].name}
					value={(data, update, index) => data[index]}
					keyBase="address-"
					view={<SingleLocationField.View />}
					edit={<SingleLocationField.Edit />}
					add={<SingleLocationField.Add />}
					addPending={isPending(nameForUpdates)}
					instancePending={(contentId) => isPending(`${nameForUpdates}.${contentId}`)}
					onChange={(data, update, index, val) => modifyRepeatable(nameForUpdates, nameForUpdates, val, <SingleLocationField.Change typeLabel={field.title} />)}
					fieldTypeLabel={field.title || "Address"}
					revertKey={revertKey}
				/>;
			case "emailAddress":
				values = getUnstructuredRepeatableValues(field);
				nameForUpdates = "unstructured." + field.field;
				if (!update.unstructured[field.field])
					update.unstructured[field.field] = [];

				return <RepeatableLinkage
					editable={editable}
					key={"unstructured_" + field.title}
					idPrefix="email-"
					data={showRepeatableValues(values.updates, values.originals) || []}
					update={update.unstructured[field.field]}
					onAdd={() => addRepeatable(nameForUpdates, nameForUpdates, { name: "", content: { name: "" } }, <SingleEmailField.Change typeLabel={field.title} />)}
					onDelete={(data, update, index) => deleteRepeatable(nameForUpdates, nameForUpdates, data[index], <SingleEmailField.Change typeLabel={field.title} />)}
					value={(data, update, index) => data[index]}
					label={(data, update, index) => field.title + ": " + data[index].name}
					keyBase="email-address-"
					view={<SingleEmailField.View />}
					edit={<SingleEmailField.Edit />}
					add={<SingleEmailField.Add />}
					addPending={isPending(nameForUpdates)}
					instancePending={(contentId) => isPending(`${nameForUpdates}.${contentId}`)}
					onChange={(data, update, index, val) => {
						modifyRepeatable(nameForUpdates, nameForUpdates, val, <SingleEmailField.Change typeLabel={field.title} />);
					}}
					fieldTypeLabel={field.title || "EmailAddress"}
				/>;
			case "repeatedText":
				values = getUnstructuredRepeatableValues(field);
				nameForUpdates = "unstructured." + field.field;
				if (!update.unstructured[field.field])
					update.unstructured[field.field] = [];

				return <RepeatableLinkage
					editable={editable}
					key={"unstructured_" + field.title}
					idPrefix="repeated-text-"
					data={showRepeatableValues(values.updates, values.originals) || []}
					update={update.unstructured[field.field]}
					onAdd={() => addRepeatable(nameForUpdates, nameForUpdates, { name: "", content: { name: "" } }, <SinglePlainTextField.Change typeLabel={field.title} />)}
					onDelete={(data, update, index) => deleteRepeatable(nameForUpdates, nameForUpdates, data[index], <SinglePlainTextField.Change typeLabel={field.title} />)}
					value={(data, update, index) => data[index]}
					label={(data, update, index) => field.title + ": " + data[index].name}
					keyBase="repeated-text-"
					view={<SinglePlainTextField.View />}
					edit={<SinglePlainTextField.Edit />}
					add={<SinglePlainTextField.Add />}
					addPending={isPending(nameForUpdates)}
					instancePending={(contentId) => isPending(`${nameForUpdates}.${contentId}`)}
					onChange={(data, update, index, val) => {
						modifyRepeatable(nameForUpdates, nameForUpdates, val, <SinglePlainTextField.Change typeLabel={field.title} />);
					}}
					fieldTypeLabel={field.title}
				/>;
			case "country":
				return (
					<DetailsLinkage
						editable={editable}
						label={field.title}
						value={showUnstructuredValue(field)}
						key={"unstructured_" + field.title}
						view={<SingleCountryField.View />}
						edit={<SingleCountryField.Edit />}
						pending={isPending(field.field)}
						onChange={(val) => updateUnstructuredField(field, val, <SingleCountryField.Change label={field.title} />)}
						revertKey={revertKey}
					/>
				);
			case "countries":
				return (
					<DetailsLinkage
						editable={editable}
						label={field.title}
						value={showUnstructuredValue(field)}
						key={"unstructured_" + field.title}
						view={<MultiCountryField.View />}
						edit={<MultiCountryField.Edit />}
						pending={isPending(field.field)}
						onChange={(val) => updateUnstructuredField(field, val, <MultiCountryField.Change label={field.title} />)}
						revertKey={revertKey}
					/>
				);
			case "listOfFields":
				values = getUnstructuredRepeatableValues(field);
				nameForUpdates = "unstructured." + field.field;
				if (!update.unstructured[field.field])
					update.unstructured[field.field] = [];


				return <RepeatableLinkage
					editable={editable}
					key={"unstructured_" + field.title}
					idPrefix="list-of-fields-"
					data={showRepeatableValues(values.updates, values.originals) || []}
					fields={field.options}
					update={update.unstructured[field.field]}
					onAdd={() => addRepeatable(nameForUpdates, nameForUpdates, { name: "", content: { data: null } }, <ListOfFields.Change typeLabel={field.title} fields={field.options} />)}
					onDelete={(data, update, index) => deleteRepeatable(nameForUpdates, nameForUpdates, data[index], <ListOfFields.Change typeLabel={field.title} fields={field.options} />)}
					fieldTitle={field.title}
					label={(data, update, index) => field.title + ": " + data[index].name}
					value={(data, update, index) => data[index]}
					keyBase="list-of-fields-"
					view={<ListOfFields.View />}
					edit={<ListOfFields.Edit />}
					add={<ListOfFields.Add />}
					addPending={isPending(nameForUpdates)}
					instancePending={(contentId) => isPending(`${nameForUpdates}.${contentId}`)}
					onChange={(data, update, index, val) => modifyRepeatable(nameForUpdates, nameForUpdates, val, <ListOfFields.Change typeLabel={field.title} fields={field.options} />)}
					fieldTypeLabel={field.title}
				/>;
			case "unique":
				const lookup = field.field.startsWith('unstructured.') ? field.field.substring("unstructured.".length) : field.field;
				return (<DetailsLinkage
					editable={editable}
					label={field.title}
					value={showUnstructuredValue(field)}
					key={"unstructured_" + field.title}
					type="text"
					view={<UniqueItem.View />}
					edit={<UniqueItem.Edit />}
					pending={isPending(field.field)}
					onChange={val => { updateUnstructuredField(field, val, <UniqueItem.Change label={field.title} />); }}
					fetchUniqueness={(value) => fetchUniqueness(lookup, value)}
				/>);
			case "ongoingInvestment":
				const groupedFields = field.groupedFields;
				return (
					<DetailsLinkage
						editable={editable}
						label={field.title}
						editSublabels={groupedFields.map(f => f.title)}
						value={[
							showUnstructuredValue(groupedFields[0]),
							showUnstructuredValue(groupedFields[1])
						]}
						key={"unstructured_" + field.title}
						view={<MultiField.View addDividers />}
						edit={<OngoingInvestmentEdit frequencyField={groupedFields[0]}
						/>}
						pending={isPending([groupedFields[0].field, groupedFields[1].field])}
						onChange={(val) => {
							updateUnstructuredField(groupedFields[0], val[0], <MultiField.Change label={groupedFields[0].title} />)
							updateUnstructuredField(groupedFields[1], val[1], <MultiField.Change label={groupedFields[1].title} />)
						}
						}
					/>
				);
			case "button":
				return (<UnstructuredButtonField key={"unstructured_" + field.title} label={field.title} endPoint={field.defaultValue} targetEntity={targetEntity} />);
			default:
		}
		return <p>field type {field.type} not implemented yet</p>
	}

	const currentValueResolver = (label) => {
		if (label.startsWith("unstructured")) {
			const unstructuredData = JSON.parse(accountFields.unstructuredData) || {};
			return unstructuredData[label.substring("unstructured".length + 1, label.length)];
		} else if (label.startsWith("account")) {
			return accountFields[label.split(".")[1]];
		}
	}

	const fetchUniqueness = function (dto) {
		return remote.post(`/modules/crm/provider/duplicate-check`, dto).then((duplicateCheckResponse) => {
			if (duplicateCheckResponse) {
				if (duplicateCheckResponse.success) {
					return { isValid: true };
				}
				return { isValid: false, errorMessage: duplicateCheckResponse.message };
			}
			return { isValid: false, errorMessage: "System error. Please try again later." };
		});
	};

	const getDifference = (currentValue, newValue, label) => { // label should really be called path.
		return [{ oldValue: currentValue, newValue, label }];
	}

	const showableChanges = (difference, label) => {
		let ignorableKeys = generalIgnorableKeys;
		if (ignorableKeysByLabel[label]) {
			ignorableKeys = ignorableKeys.concat(ignorableKeysByLabel[label]);
		}

		return difference.filter((data) => {
			var relativePath = data.label.startsWith(label) ? data.label.substring(label.length + 1) : data.label;
			// for arrays, we want to use the key to filter and for objects we want to use the relative path.
			return !ignorableKeys.includes(data.key) && !ignorableKeys.includes(relativePath);
		});
	}

	const getRequirements = (changedFields = updateList) => {
		remote.post("/modules/crm/client/details-update-requirements/HWP", changedFields.map((chng) => (chng.endsWith(".update") ? chng.slice(0, -7) : chng)))
		.then((response) => {
			const listOfFieldsRequiringDocs = Object.keys(response.data.docsByField)
			let allFieldsInSchema = []
			schema.forEach((group) => {
				group.fields.forEach((field) => {
					allFieldsInSchema.push(field)
				})
			})
			const requirementsWithTitles = listOfFieldsRequiringDocs.map((field) => {
				let schemaField = allFieldsInSchema.find((schemaField) => schemaField.field === field)
				let obj = { field, docs: response.data.docsByField[field], title: schemaField.title }
				return obj
			})
			const listOfDocsRequired = requirementsWithTitles.map(field => {
				return field.docs
			}).flat()
			const requiresDocs = listOfDocsRequired.length > 0
			
			setRequiresDocs(!!requiresDocs)
			if (requiresDocs) {
				setDetailsUpdateRequirements(requirementsWithTitles)
			} else {
				setDetailsUpdateRequirements(null)
			}
		});
	}

	// ************************************** REPEATABLES **************************************


	const addRepeatable = (path, updatePath, value, DisplayComponent) => {
		if (value.content && (value.content.id === null || value.content.id === undefined)) {
			value.content.id = Math.floor(-Math.random() * 100000);
		}
		value.isAdd = true;
		const bits = updatePath.split(".");

		const holder = update[bits[0]]; //eg account, client, accountPeripherals, clientSummary
		let array = holder[bits[1]]; //eg. addresses, emailAddresses
		if (!array) {
			array = [];
			holder[bits[1]] = array;
		}
		array.push(value);

		const newUpdateList = [...updateList];
		const newUpdateItems = [...updateItems];
		const addPath = path + ".add";
		if (newUpdateList.indexOf(addPath) < 0) {
			newUpdateList.push(addPath);
			newUpdateItems.push({ change: addPath, difference: {}, isRepeatable: true, DisplayComponent });
		}

		setUpdate(update)
		setUpdateList(newUpdateList)
		setUpdateItems(newUpdateItems)
		getRequirements(updateList);
	}

	const deleteRepeatable = (path, updatePath, value, DisplayComponent) => {
		const bits = updatePath.split(".");

		const holder = update[bits[0]]; //either account or accountPeripherals
		const array = holder[bits[1]]; //eg. addresses

		const newUpdateList = [...updateList];
		const newUpdateItems = [...updateItems];

		const indexInUpdate = array.findIndex(item => item.content && item.content.id === value.content.id);

		//Parse if necessary (unstructured)
		let currentValue = { content: {} };
		if (value.content.id >= 0) {
			let data = currentValueResolver(path);
			if (typeof data === "string") {
				data = JSON.parse(data);
			}
			currentValue = data.find(item => {return item.content && item.content.id === value.content.id});
			
		}
		
		if (value.content.id < 0) {
			//removing a newly added...
			if (indexInUpdate >= 0) {
				array.splice(indexInUpdate, 1);
				
				const changeIndex = newUpdateList.indexOf(path + ".add");
				delete newUpdateItems[changeIndex].difference[value.content.id];
				
				//check whether we still need the update in updateList
				if (Object.keys(newUpdateItems[changeIndex].difference).length === 0) {
					//no more adds
					newUpdateItems.splice(changeIndex, 1);
					newUpdateList.splice(changeIndex, 1);
				}
			}

			setUpdate(update)
			setUpdateItems(newUpdateItems)
			setUpdateList(newUpdateList)
			return;
		}
		
		if (indexInUpdate >= 0) {
			array[indexInUpdate].isDelete = true;
		} else {
			array.push(value);
			value.isDelete = true;
		}

		const deletePath = path + ".delete";
		const changeIndex = newUpdateList.indexOf(deletePath);

		const difference = getDifference(currentValue.content, {}, path).concat(getDifference(currentValue.name, "", path + ".name"));

		if (changeIndex < 0) {
			//no deletes yet
			newUpdateList.push(deletePath);
			newUpdateItems.push({ change: deletePath, difference: { [value.content.id]: showableChanges(difference, path) }, isRepeatable: true, DisplayComponent });
		} else {
			//has deletes
			newUpdateItems[changeIndex].difference[value.content.id] = showableChanges(difference, path);

		}
		setUpdate(update)
		setUpdateItems(newUpdateItems)
		setUpdateList(newUpdateList)
		getRequirements(newUpdateList);
	}

	const modifyRepeatable = (path, updatePath, value, DisplayComponent) => {
		const originalPath = path;
		const bits = updatePath.split(".");

		delete value.isAdd;
		delete value.content.isAdd;

		const holder = update[bits[0]]; //either account or accountPeripherals
		const array = holder[bits[1]]; //eg. addresses

		const indexInUpdate = array.findIndex(item => item.content && item.content.id === value.content.id);
		if (indexInUpdate >= 0) {
			array.splice(indexInUpdate, 1, value);
		} else {
			array.push(value);
		}

		const newUpdateList = [...updateList];
		const newUpdateItems = [...updateItems];

		if (value.content.id < 0) {
			path = path + ".add";
		}

		const changeIndex = newUpdateList.indexOf(path);

		//Parse if necessary (unstructured)
		let currentValue = { content: {} };
		if (value.content.id >= 0) {
			let data = currentValueResolver(originalPath);
			if (typeof data === "string") {
				data = JSON.parse(data);
			}
			currentValue = data.find(item => item.content && item.content.id === value.content.id);
		}
		const difference = getDifference(currentValue.content, value.content, originalPath).concat(getDifference(currentValue.name, value.name, originalPath + ".name"));

		if (changeIndex < 0) {
			//repeatable has not been modified before
			if (difference.length > 0) {
				let changed = difference.reduce((prev, curr) => prev || !_.isEqual(curr.oldValue, curr.newValue), false);

				//has difference
				if (changed) {
					newUpdateList.push(path);
					newUpdateItems.push({ change: path, difference: { [value.content.id]: showableChanges(difference, originalPath) }, isRepeatable: true, DisplayComponent });
				}
			}
		} else {
			//repeatable has been modified before
			if (difference.reduce((eq, diff) => eq && _.isEqual(diff.oldValue, diff.newValue), true)) {
				//Back to original
				//no difference
				delete newUpdateItems[changeIndex].difference[value.content.id]
				if (Object.keys(newUpdateItems[changeIndex].difference).length === 0) {
					//no more difference
					newUpdateItems.splice(changeIndex, 1);
					newUpdateList.splice(changeIndex, 1);
				}
			} else {			//has difference
				newUpdateItems[changeIndex].difference[value.content.id] = showableChanges(difference, originalPath);
			}
		}
		setUpdate(update)
		setUpdateItems(newUpdateItems)
		setUpdateList(newUpdateList)
		getRequirements(newUpdateList);
	}

	const generateId = () => {

		return -Math.floor(Math.random() * 100000);
	}

	const testInternationalNumberSeperately = (idd, number) => {
		return !!idd && /^\+[0-9]{1,4}$/.test(idd.replace(/\s/g, "")) //assumes that the prefix is a string!!!
			&& /^[1-9][0-9]{6,10}$/.test(number);//also total length test?
	}

	const showRepeatableValues = (updateValues, initialValues) => {
		if (!initialValues) {
			return updateValues || [];
		}
		const values = [];
		//go through initial, replacing any that have been modified
		initialValues.forEach(element => {
			const updatedElement = updateValues.find(val => val.content.id === element.content.id);
			if (updatedElement && updatedElement.isDelete) {
				//ignore this one
			} else if (updatedElement) {
				values.push(updatedElement);
			} else {
				values.push(element);
			}
		});

		//look through updates for adds
		if (updateValues) {
			updateValues.forEach(element => {
				if (element.content && element.content.id < 0 && !element.isDelete) {
					values.push(element);
				}
			});
		}

		return values;
	}



	// ************************************** NON-REPEATABLES **************************************


	const updateField = (label, change, DisplayComponent) => {
		let split = label.replace(/\[/g, ".").replace(/\]/g, "").split(".");
		let newUpdate = { ...update };
		// eslint-disable-next-line
		split.slice(0, -1).reduce((parent, key) => {
			if (!parent[key]) {
				parent[key] = {};
			}
			return parent[key];
		}, newUpdate)[split[split.length - 1]] = change;
		setUpdate(newUpdate);
		updateChangeList(label, "update", change, DisplayComponent);
	}

	const updateChangeList = (label, change, value, DisplayComponent,) => {
		/**
		 * change possibles
		 * client.value.update
		 * client.list.add
		 * client.list.i.update
		 * client.list.i.remove
		 *
		 * label = "client.value", change = "update/add/etc."
		 */

		const changes = [...updateList];
		const newUpdateItems = [...updateItems];

		let currentValue = currentValueResolver(label);

		const changeIndex = changes.findIndex(e => {
			return e.indexOf(label) === 0;
		});


		const difference = getDifference(currentValue, value, label);
		if (changeIndex > -1) {
			let reset = false;
			if (currentValue === undefined) {
				//This was a field that wasn't originally set - we've set it
				//But have we potentially also unset it?
				if (value === false || value === "" || value === null) {
					reset = true;
				}
			} else if (_.isEqual(difference[0].oldValue, difference[0].newValue)) {
				reset = true;
			}

			if (reset) {
				// back to original state
				changes.splice(changeIndex, 1);
				newUpdateItems.splice(changeIndex, 1);
			} else {
				// update differences
				const newChange = label.concat(".", change);
				newUpdateItems[changeIndex] = { difference: difference, change: newChange, isRepeatable: false, DisplayComponent };
			}
		} else {
			if (difference.length > 0 && !_.isEqual(difference[0].oldValue, difference[0].newValue) && !(currentValue === undefined && (value === false || value === ""))) {
				// add new changes
				const newChange = label.concat(".", change);
				changes.push(newChange);
				newUpdateItems.push({ difference, change: newChange, isRepeatable: false, DisplayComponent });
			}
		}

		//set our state
		setUpdateItems(newUpdateItems)
		setUpdateList(changes)
		getRequirements(changes);
	}

	const removeChanges = () => {
		setUpdate({
			account: {
				addresses: [],
				interests: [],
				dependants: [],
				phone: [],
				email: [],
				taxNumbers: [],
				regularDeposits: [],
				regularWithdrawals: [],
				bankAccounts: [],
				debitAuthorities: [],
				creditApprovals: [],
			},
			accountPeripherals: {
				associatedClients: {},
			},
			unstructured: {},
			attachments: [],
			comments: "",
		})
		setUpdateItems([])
		setUpdateList([])
		setRevertKey(revertKey + 1);
	}

	const setData = () => {

		setAccount(props.account)
		storage.refresh(`/modules/crm/provider/accounts/${props.account.id}`).then((providerAccount) => {
			const innerScopeAccount = { ...props.account, ...providerAccount }
			//Include unstructured for lookup of current value
			const unstructured = JSON.parse(innerScopeAccount.unstructuredData || "{}");

			setAccountFields(innerScopeAccount)
			setPending(initPending(innerScopeAccount))
			setUnstructured(unstructured)

			const accountIDEncoded = encodeURI(JSON.stringify({account:innerScopeAccount.id, number:innerScopeAccount.number}));
			remote.get(`/modules/crm/client/bank-verification-url?state=${accountIDEncoded}`).then(setBankUrl);

			Promise.all([
				remote.get(`/modules/crm/accounts/${props.account.id}/model`),
				storage.getOrFetch(`/modules/crm/classification-scheme/${props.account.classificationScheme.id}/model-data`),
			]).then(([modelResp, scheme]) => {
				setAccountFields({ ...innerScopeAccount, modelName: { model: modelResp.data, scheme } })
			}).then(() => {
				storage.getOrFetch("/modules/crm/client/schema/HWP/account/requirements").then(s => setSchema(s.groups));
			});

		})
	};

	useEffect(() => {
		setData()
	}, [])

	const initPending = (singlePage) => {
		var tempPending = {}
		if (singlePage.pending == null)
			return tempPending;//return an empty object so that accessing fields will be detected as null
		/** 
		 * Using sectionFor, look at each key in the pending object
		 * Append the labelFor a field to the pending of an 
		 */
		for (var key in singlePage.pending) {
			let section = sectionFor(key.split("."));
			if (tempPending[section] == null)
				tempPending[section] = []
			let label = singlePage.pending[key][0].fieldName;
			tempPending[section].push(label);
		}
		/**
		 * Use this part iff: using message list not message
		 * Take all of the lists, convert them into single strings
		 */
		for (var section in tempPending) {
			tempPending[section] = "Pending changes: " + tempPending[section].join(", ")//in string from
		}
		tempPending.raw = singlePage.pending;
		return tempPending;
	}
	// Function to display values that are used with DetailsLinkage.js on the client, non persons, and accounts details pages.
	const showValue = (updateValue, initialValue) => {
		const value = updateValue === "" ? "" : (updateValue || initialValue);
		return value;
	}

	const leaveUnsavedChanges = (onClose) => {
		storage.get("portal.modals")
		.show(
			ModalTypes.GenericMessageModal, 
			{ 
				message: "This process will direct you away from this page and you will lose any unsaved changes.", 
				header: "Are you sure you want to leave?", 
				onClose , 
				cancel: true
			})
	}

	const renderCore = (schemaObj) => {
		const editable = schemaObj.currentRules?.find(r => r.action == null)?.editable;
		const addable = schemaObj.currentRules?.find(r => r.action == 'add')?.editable;
		const deletable = schemaObj.currentRules?.find(r => r.action == 'delete')?.editable;

		switch (schemaObj.field) {
			case "account.salutation":
				return <DetailsLinkage
					key="salutation"
					editable={editable}
					label={schemaObj.title || "Salutation"}
					value={showValue(update.account.salutation, account.salutation)}
					view={<SingleItem.View />}
					edit={<SingleItem.Edit />}
					onChange={val => updateField("salutation", val, <SingleItem.Change label={schemaObj.title || "salutation"} />, update, setUpdate, updateList, setUpdateList, updateItems, setUpdateItems, currentValueResolver)}
					pending={isPending("account.salutation")}
				/>;
			case "account.name":
				return <DetailsLinkage
					key="name"
					editable={editable}
					label={schemaObj.title || "Account Name"}
					value={account.name}
					view={<SingleItem.View />}
					edit={<SingleItem.Edit />}
					onChange={val => updateField("account.name", val, <SingleItem.Change label={schemaObj.title || "Account Name"} />, update, setUpdate, updateList, setUpdateList, updateItems, setUpdateItems, currentValueResolver)}
					pending={isPending("account.name")}
				/>;
			case "account.serviceLevel":
				return <DetailsLinkage
					key="serviceLevel"
					editable={editable}
					label={schemaObj.title || "Service Level"}
					value={accountFields.serviceLevel}
					options={serviceLevels}
					view={<NameAndId.View />}
					edit={<NameAndId.Edit />}
					onChange={val => updateField("account.serviceLevel", val, <NameAndId.Change label={schemaObj.title || "Service Level"} />, update, setUpdate, updateList, setUpdateList, updateItems, setUpdateItems, currentValueResolver)}
					pending={isPending("account.serviceLevel")}
					validation={schemaObj.validation}
					revertKey={revertKey}
				/>;
			case "account.accountType":

				return <DetailsLinkage
					key="accountType"
					editable={editable}
					label={schemaObj.title || "Account Type"}
					value={account.accountType}
					options={[account.accountType]}
					view={<NameAndId.View />}
					edit={<NameAndId.Edit />}
					onChange={val => updateField("account.accountType", val, <NameAndId.Change label={schemaObj.title || "Account Type"} />, update, setUpdate, updateList, setUpdateList, updateItems, setUpdateItems, currentValueResolver)}
					pending={isPending("account.accountType")}
					validation={schemaObj.validation}
					revertKey={revertKey}
				/>
			case "account.managementBasis":

				return <DetailsLinkage
					key="managementBasis"
					editable={editable}
					label={schemaObj.title || "Service"}
					value={account.managementBasis}
					options={[account.managementBasis]}
					view={<NameAndId.View />}
					edit={<NameAndId.Edit />}
					onChange={val => {
						updateField("account.managementBasis", val, <NameAndId.Change label={schemaObj.title || "Service"} />, update, setUpdate, updateList, setUpdateList, updateItems, setUpdateItems, currentValueResolver);
						updateField("account.subService", { id: 7, name: "None" }, <NameAndId.Change label={"SubService"} />, update, setUpdate, updateList, setUpdateList, updateItems, setUpdateItems, currentValueResolver);
					}}
					pending={isPending("account.managementBasis")}
					validation={schemaObj.validation}
					revertKey={revertKey}
				/>
			case "account.subService":

				const stateManagementBasis = account.managementBasis
				const optionKey = !!stateManagementBasis ? stateManagementBasis.id : 5;
				return <DetailsLinkage
					key="subService"
					editable={editable}
					label={schemaObj.title || "Classification"}
					value={account.subService}
					options={[account.subService]}
					view={<NameAndId.View />}
					edit={<NameAndId.Edit />}
					onChange={val => updateField("account.subService", val, <NameAndId.Change label={schemaObj.title || "Classification"} />, update, setUpdate, updateList, setUpdateList, updateItems, setUpdateItems, currentValueResolver)}
					pending={isPending("account.subService")}
					validation={schemaObj.validation}
					revertKey={revertKey}
				/>
			
			default:
		}

		//Rest are repeatables
		const key = schemaObj.field + "Repeatable";
		let displayTitle = "", updateData, dataData, blankForInsert = {}, refName = schemaObj.field, Component, OverrideView = null, label, extraProps = {};

		switch (schemaObj.type) {
			case "addresses":
				displayTitle = schemaObj.title || "Address";
				updateData = update.account.addresses;
				dataData = accountFields.addresses;
				blankForInsert = { name: "", content: { fullAddress: "", additionalData: "{}" } };
				Component = SingleLocationField;
				label = (data, update, index) => data[index].name || displayTitle;
				extraProps = { possibleLabels: schemaObj.possibleLabels ? [...schemaObj.possibleLabels] : null }
				break;
			case "phones":
				displayTitle = schemaObj.title || "Phone";
				updateData = update.account.phone;
				dataData = accountFields.phone;
				blankForInsert = { name: "", content: { extension: "", idd: "", number: "" } };
				Component = SinglePhoneField;
				label = (data, update, index) => (data[index].name ? data[index].name : displayTitle);
				extraProps = {
					possibleLabels: schemaObj.possibleLabels ? [...schemaObj.possibleLabels] : null,
					validate: ({ content }) => ({ isValid: testInternationalNumberSeperately("+" + (content.idd && content.idd.replace ? content.idd.replace("+", "") : content.idd), content.number) }),
				};
				break;
			case "taxNumbers":
				displayTitle = schemaObj.title || "TaxNumber";
				updateData = update.account.taxNumbers;
				dataData = accountFields.taxNumbers;
				refName = schemaObj.field;
				blankForInsert = { name: "Tax Number -", content: { country: "", tin: "" } };
				Component = TaxNumberField;
				label = (data, update, index) => (data[index].name ? data[index].name : displayTitle);
				extraProps = {
					validate: (val) =>  {return val && val.content ? taxValidator(val.content) : { isValid: true }},
					validator: schemaObj.validation
				}
				break;
			case "emailAddresses":
				displayTitle = schemaObj.title || "Email Address";
				updateData = update.account.email;
				dataData = accountFields.email;
				blankForInsert = { name: "", content: { id: generateId(), name: "", isAdd: true } };
				Component = SingleEmailField;
				label = (data, update, index) => data[index].name;
				extraProps = {
					possibleLabels: schemaObj.possibleLabels ? [...schemaObj.possibleLabels] : null,
					validate: ({ content }) => (validateEmail(content.name))
				}
				break;
			case "bankAccounts":
				return <BankAccounts 
					bankAccounts={showRepeatableValues( update.account.bankAccounts, accountFields.bankAccounts)} 
					addBankURL={bankUrl.url}
					remote={remote} 
					onDelete={(data, update, index) => {deleteRepeatable(refName, refName, data[index], <BankAccRepeatableChange typeLabel={displayTitle} />)}} 
					addBankPreflightCheck={changesMade ? (onClose) => leaveUnsavedChanges(onClose) :null}
					isReadOnly={!editable}
					isAddable={addable}
					isDeletable={deletable}
				/>
				break;
			default:
				return <p>unrecognised field: {schemaObj.field}</p>
		}

		if (!Component) {
			return <p>No component for {schemaObj.field}</p>
		}

		if (!showRepeatableValues(updateData, dataData) || showRepeatableValues(updateData, dataData) < 1) {
			return null
		}

		return <RepeatableLinkage
			key={key}
			idPrefix={schemaObj.field}
			data={showRepeatableValues(updateData, dataData) || []}
			update={updateData || []}
			onAdd={() => addRepeatable(refName, refName, blankForInsert, <Component.Change typeLabel={displayTitle} />, update, setUpdate, updateList, setUpdateList, updateItems, setUpdateItems)}
			onDelete={(data, update, index) => deleteRepeatable(refName, refName, data[index], <Component.Change typeLabel={displayTitle} />)}
			label={label}
			value={(data, update, index) => data[index]}
			keyBase={key}
			view={OverrideView || <Component.View />}
			edit={<Component.Edit />}
			add={<Component.Add />}
			addPending={true}
			instancePending={(contentId) => isPending(`${refName}.${contentId}`)}
			onChange={(data, update, index, val) => modifyRepeatable(refName, refName, val, <Component.Change typeLabel={displayTitle} />)}
			fieldTypeLabel={displayTitle}
			editable={editable}
			revertKey={revertKey}
			{...extraProps}
		/>;
	}

	// ************ modal ************** //
	const onAttachmentsChange = (newAttachments) => {
		setUpdate({ ...update, attachments: newAttachments })
	}

	const getSubmission = () => {
		const submission = {
			event: {
				source: "sysgen"
			},
			updates: updateList.map((label) => {
				if (label.endsWith(".update")) {
					return label.slice(0, -7)
				}
				return label;
			}),
			account: update.account,
			accountPeripherals: update.accountPeripherals,
			unstructuredData: update.unstructured,
			attachments: update.attachments,
			comments: update.comments
		};
		submission.account.id =
			account.id;
		return submission;
	}

	const submitChangeRequest = () => {
		const submission = getSubmission();
		setSubmittingChange(true)
		remote.post("/modules/crm/client/details-update/HWP", submission).then((resp) => {
			if (resp.message) {
				changesSubmitted(false)
				let remoteErrors;
				try {
					remoteErrors = JSON.parse(resp.message);
				} catch (err) {
					remoteErrors = [resp.message];
				}
				setErrors(remoteErrors)
				setSubmittingChange(false)
			} else {
				storage.refresh("/modules/crm/client/accounts?dynamicFields=CustodyType").then((res) => {
					let newCurrentAccount=res.find(acc => acc.id === account.id)
					newCurrentAccount && setAccount(newCurrentAccount)
				})
				storage.refresh(`/modules/crm/provider/accounts/${account.id}`).then((providerAccount) => {
					const innerScopeAccount = { ...account, ...providerAccount }
					//Include unstructured for lookup of current value
					const unstructured = JSON.parse(innerScopeAccount.unstructuredData || "{}");

					setAccountFields(innerScopeAccount)
					setPending(initPending(innerScopeAccount))
					setUnstructured(unstructured)

					Promise.all([
						remote.get(`/modules/crm/accounts/${account.id}/model`),
						storage.getOrFetch(`/modules/crm/classification-scheme/${account.classificationScheme.id}/model-data`),
					]).then(([modelResp, scheme]) => {
						setAccountFields({ ...innerScopeAccount, modelName: { model: modelResp.data, scheme } })
					})

				})
				setUpdate({
					account: {
						addresses: [],
						interests: [],
						dependants: [],
						phone: [],
						email: [],
						taxNumbers: [],
						regularDeposits: [],
						regularWithdrawals: [],
						bankAccounts: [],
						debitAuthorities: [],
						creditApprovals: [],
					},
					accountPeripherals: {
						associatedClients: {},
					},
					unstructured: {},
					attachments: [],
					comments: "",
				})
				setUpdateList([])
				setUpdateItems([])
				setDetailsUpdateRequirements(null)
				changesSubmitted(true)
			}
		}).then(() => {
			setSubmittingChange(false)
		});
		// 	setSubmittingChange(false)
		// 	changesSubmitted(true)
		// 	setUpdate({ ...update, attachments: [] })
		// 	// set the rest of the states to blank
		// }
		// });
	}

	const changesSubmitted = (success) => {
		storage.get("portal.modals").close();
		storage.get("portal.modals").show(ModalTypes.ChangesSubmittedModal, { success });
	}

	const rollbackAttachment = () => {
		setUpdate({ ...update, attachments: [] });
	}

	const openModal = () => {
		storage.get("portal.modals").show(ModalTypes.SupportingDocumentationModal, { onAttachmentsChange, submitChangeRequest, rollbackAttachment, detailsUpdateRequirements, account: account, clearAttachments });
	}

	const clearAttachments = () => {
		let updateWithAttachmentsRemoved = update
		updateWithAttachmentsRemoved.attachments = []
		setUpdate(updateWithAttachmentsRemoved)
	}

	const hasBankAccountPendingChanges = () => {
		//quick and dirty, needs to be moved to core components along with others. Then pending deletes will show with their respective line instead of at top.
		console.log("Have pendings: ", pending)
		console.log(isPending('account.bankAccounts'));
		if (isPending('account.bankAccounts')) {
			return true;
		}
		console.log("Checking keys such that key starts with account.bankAccounts")
		console.log(Object.keys(pending));
		return pending.raw && Object.keys(pending.raw).find(key => key.startsWith('account.bankAccounts'));
	}

	return <>
		<Mobile>
			<DefaultMobileBanner />
		</Mobile>
		<Desktop>
			<Banner>
				<BannerInfoText>
					<BannerInfoMainText>{account.name}</BannerInfoMainText>
					<BannerInfoHighlightText>{displayPortalAccType(getPortalAccType(account))}</BannerInfoHighlightText>
				</BannerInfoText>
				<BannerNavLinks parent={LINK_ITEMS().SETTINGS} />
			</Banner>
		</Desktop>
		<PortalContentWrapper>
			{schema && schema.length > 0 && schema.map((group, idx) => {
				const isBankAcc = group.title === "Bank Accounts";
				
				return (
					<div key={"group-" + idx}>
						<CardContainer>
							<Card>
								<CardHeader className={isBankAcc ? "bank-acc-header" : ""}>
									<h3>{group.title === "AccountInfo" ? "General" : group.title}</h3>
									{/* 
										This block hardcodes bank accounts as its own group. 
										If bank accounts are placed in another group in the schema this button needs to be moved or else bank accounts will not be able to be added 
									*/}
									{isBankAcc && group.fields.some((field) => field.currentRules?.find(r => r.action == 'add')?.editable) && (<>
										<ToolTip title="Add a new bank account">
											<AddCircle 
												className="add-bank-acc-button" 
												onClick={() => {
													if (changesMade) {leaveUnsavedChanges(() => window.location.href = bankUrl.url)}
													else {window.location.href = bankUrl.url}
												}} 	
											/>
											
										</ToolTip>
										{/* add pending icon if: account.bankAccounts.  TODO this should be moved to component in core-components */}
										{hasBankAccountPendingChanges() && <ToolTip title={"Pending changes"}>
											<Info className="bank-acc-pending-button pending" />
										</ToolTip>}
										</>
									)}
								</CardHeader>
								<Row>
									{group.fields.map((field, idx) => {
										let renderable =
											field.fieldType === "DYNAMIC"
												? fieldToDetailsLinkage(
														field,
														update,
														(fieldName, value) => fetchUniqueness({ fieldName, value, type: "account", ownerId: account.id }),
														account
												  )
												: renderCore(field);
										return renderable && field.type === "bankAccounts" ? (
											<Col key={"field-" + field.title} xs="12" className="pr-xl-3 bank-accounts-col">
												{renderable}
											</Col>
										) : (
											<Col key={"field-" + field.title} xs="12" xl="6" className="pr-xl-3">
												{renderable}
											</Col>
										);
									})}
								</Row>
							</Card>
						</CardContainer>
					</div>
				);
			})}
			<SaveChangesBanner openModal={requiresDocs ? openModal : submitChangeRequest} changesSubmitted={changesSubmitted} display={changesMade} submittingChange={submittingChange} removeChanges={removeChanges} />
			{/* <div>
				<a href={bankUrl && bankUrl.url}>Add bank account</a>
			</div> */}
		</PortalContentWrapper>
	</>
}

export default withThreeSkyeGlobal(withAccountInfo(AccountInformation));
