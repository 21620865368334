import React, { Component } from "react";
import { Link } from "react-router-dom";
import Input from "../../../../core-components/inputs/Input";
import VerticalSpacer from "../../../../core-components/layouts/VerticalSpacer";
import Button from "../../../../core-components/inputs/Button";
// import { ReCaptcha } from "react-recaptcha-google";
import "./ConfirmEmail.scss";
import { withThreeSkyeGlobal } from "@threeskye/global";
import CommonFunctions from "../../../../core-components/functions/CommonFunctions";

//Change to hobson's or 3SKYE's one
// const recaptchaSiteKey = "6LenenQUAAAAAL67YU3FNQnFsV8hnzR2yvbhbBvD";

class ConfirmEmail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			emailIsValid: false,
			loading: false,
			// recaptchaSuccessful: false,
		};
		// this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
		// this.verifyCallback = this.verifyCallback.bind(this);
		this.setEmail = this.setEmail.bind(this);
		this.sendCode = this.sendCode.bind(this);
	}

	componentDidMount() {
		// if (this.captcha) {
		// 	this.captcha.reset();
		// }
	}

	// onLoadRecaptcha() {
	// 	if (this.captcha) {
	// 		this.captcha.reset();
	// 	}
	// }

	// verifyCallback(recaptchaToken) {
	// 	if (recaptchaToken.length) {
	// 		this.setState({ recaptchaSuccessful: true });
	// 		this.recaptchaToken = recaptchaToken;
	// 	}
	// }

	setEmail(email) {
		const emailIsValid = CommonFunctions.isEmailValid(email);
		this.setState({ email, emailIsValid, errorMessage: "" });
	}

	sendCode() {
		const { onConfirm } = this.props;
		const { email } = this.state;
		const data = { email };
		this.setState({ loading: true });
		this.props.remote
			.post("/um/hwp/send-reset-code", data)
			.then(response => onConfirm(email, response.key, response.type), error => this.setState({ loading: false, errorMessage: error.message }));
	}

	render() {
		const { loading, email, emailIsValid } = this.state;
		return (
			<div id="forgot-page">
				<Input label="email" name="email" value={email} placeholder="me@example.com" onChange={this.setEmail} />
				{/* <ReCaptcha
					ref={el => (this.captcha = el)}
					size="normal"
					render="explicit"
					sitekey={recaptchaSiteKey}
					onloadCallback={this.onLoadRecaptcha}
					verifyCallback={this.verifyCallback}
					expiredCallback={this.verifyExpired}
				/>
				<VerticalSpacer size="14px" /> */}
				<Button loading={loading} disabled={!emailIsValid} onClick={this.sendCode}>
					reset your password
				</Button>
				<VerticalSpacer size="14px" />
				<Link to="/">Back to login</Link>
			</div>
		);
	}
}

export default withThreeSkyeGlobal(ConfirmEmail);
