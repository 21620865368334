import React, { Component } from "react";
import Input from "../../../../core-components/inputs/Input";
import VerticalSpacer from "../../../../core-components/layouts/VerticalSpacer";
import Button from "../../../../core-components/inputs/Button";
import CommonFunctions from "../../../../core-components/functions/CommonFunctions";
import withRouteChange from "@threeskye/route-change";
import CenteredLogo from "../CenteredLogo";

class GenericWelcome extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			emailIsValid: false,
			loading: false,
		};
		this.setEmail = this.setEmail.bind(this);
		this.goToWelcomePage = this.goToWelcomePage.bind(this);
	}

	setEmail(email) {
		const emailIsValid = CommonFunctions.isEmailValid(email);
		this.setState({ email, emailIsValid, errorMessage: "" });
	}

	goToWelcomePage() {
		const { email } = this.state;
		this.props.changeRoute(`/welcome/${btoa(email)}`);
	}

	render() {
		const { email, emailIsValid } = this.state;
		return (
			<CenteredLogo>
				<div id="forgot-page">
					<p>Welcome to the Hobson Wealth customer portal</p>
					<VerticalSpacer size="14px" />
					<p>Please enter your email address</p>
					<VerticalSpacer size="14px" />
					<Input label="email" name="email" value={email} placeholder="me@example.com" autoComplete="one-time-code" onChange={this.setEmail} autoFocus />
					<VerticalSpacer size="14px" />
					<Button disabled={!emailIsValid} onClick={this.goToWelcomePage}>
						Submit
					</Button>
				</div>
			</CenteredLogo>
		);
	}
}

export default withRouteChange(GenericWelcome);
