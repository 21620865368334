import React from "react";
import ChangeYourPassword from "./ChangeYourPassword";
// import TwoFactorAuth from "./TwoFactorAuth/TwoFactorAuth";
import Banner from "../../Banner";
import { LINK_ITEMS } from "../../CustomerPortalPage";
import BannerInfoText, { BannerInfoIntroText } from "../../../../core-components/misc/BannerInfoText";
import { withAccountInfo } from "../../../../core-components/contexts/AccountsContext";
import BannerNavLinks from "../../../../core-components/layouts/BannerNavLinks"
import PortalContentWrapper from "../../PortalContentWrapper";
import Mobile from "../../../../core-components/layouts/Mobile";
import DefaultMobileBanner from "../../DefaultMobileBanner";
import Desktop from "../../../../core-components/layouts/Desktop";
import {useLocation} from "react-router-dom"

const MyDetails = ({ account, thirdPartyView }) => {
let location = useLocation()
	return <>
		<Mobile>
			<DefaultMobileBanner thirdPartyView={thirdPartyView} />
		</Mobile>
		<Desktop>
			<Banner thirdPartyView={thirdPartyView}>
				<BannerInfoText>
					<BannerInfoIntroText>{thirdPartyView ? "You’re now viewing your Third Party accounts" : "Edit your account details, password, and 2FA"}</BannerInfoIntroText>
				</BannerInfoText>
				{!thirdPartyView && <BannerNavLinks thirdPartyView={thirdPartyView} parent={LINK_ITEMS().SETTINGS} />}
			</Banner>
		</Desktop>
		<PortalContentWrapper thirdPartyView={thirdPartyView} >
			<div id="portal-my-details">
				{/* <EditAccountDetails /> */}
				{/* <TwoFactorAuth /> */}
				<ChangeYourPassword />
			</div>
		</PortalContentWrapper>
	</>
}

export default withAccountInfo(MyDetails);
