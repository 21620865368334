import React, { Component } from "react";
import CardContainer from "../../../../core-components/card/CardContainer";
import Card from "../../../../core-components/card/Card";
import CardHeader from "../../../../core-components/card/CardHeader";
import { withThreeSkyeGlobal } from "@threeskye/global";
import UpcomingOrders from "./StandingOrdersTable";
import HorizontalListRow from "../../../../core-components/lists/horizontal-list/HorizontalListRow";
import HorizontalListItem from "../../../../core-components/lists/horizontal-list/HorizontalListItem";
import { withAccountInfo, PortalAccTypes, getPortalAccType, displayPortalAccType } from "../../../../core-components/contexts/AccountsContext";
import Desktop from "../../../../core-components/layouts/Desktop";
import Mobile from "../../../../core-components/layouts/Mobile";
import Banner from "../../Banner";
import BannerInfoText, { BannerInfoMainText, BannerInfoHighlightText } from "../../../../core-components/misc/BannerInfoText";
import PortalContentWrapper from "../../PortalContentWrapper";
import DefaultMobileBanner from "../../DefaultMobileBanner";

const NavigatorContent = withAccountInfo(
	class extends Component {
		render() {
			const { user, account } = this.props;
			return (
				<>
					<HorizontalListRow>
						<HorizontalListItem label="Account Name:" value="Public Trust Hobson" />
						<HorizontalListItem label="Account Number:" value="02-0506-0229347-000" />
					</HorizontalListRow>
					<HorizontalListRow>
						<HorizontalListItem label="Particulars:" value={user.familyName} />
						<HorizontalListItem label="Code:" value={account.remoteSystemId} />
						<HorizontalListItem label="Reference:" value="Your IRD Number" />
					</HorizontalListRow>
				</>
			);
		}
	}
);
const PortfolioContent = withAccountInfo(
	class extends Component {
		render() {
			const { account } = this.props;
			return (
				<>
					<HorizontalListRow>
						<HorizontalListItem label="Account Name:" value="Hobson Wealth Custodian Limited" />
						<HorizontalListItem label="Account Number:" value="01-1839-0936183-00" />
					</HorizontalListRow>
					<HorizontalListRow>
						<HorizontalListItem label="Reference:" value={account.number} />
					</HorizontalListRow>
				</>
			);
		}
	}
);

class StandingOrders extends Component {
	contentComponents = {
		[PortalAccTypes.NAVIGATOR]: NavigatorContent,
		[PortalAccTypes.PORTFOLIO]: PortfolioContent,
	};

	render() {
		const { account, portalAccountType } = this.props;
		const Content = this.contentComponents[getPortalAccType(account)];
		return (
			<>
				<Mobile>
					<DefaultMobileBanner />
				</Mobile>
				<Desktop>
					<Banner>
						<BannerInfoText>
							<BannerInfoMainText>{account.name}</BannerInfoMainText>
							<BannerInfoHighlightText>{displayPortalAccType(portalAccountType)}</BannerInfoHighlightText>
						</BannerInfoText>
					</Banner>
				</Desktop>
				<PortalContentWrapper>
					<div className="card-row">
						<CardContainer xs={12}>
							<Card>
								<CardHeader>
									<h3>Payments</h3>
								</CardHeader>
								<h4 className="mt-4 mb-2">Topping up your account is simple!</h4>
								<Desktop>
									<br />
								</Desktop>
								<p>To make a voluntary contribution, login to your internet banking provider and make a payment using the following bank account details.</p>
								<br />
								{Content && <Content />}
							</Card>
						</CardContainer>
						<UpcomingOrders />
					</div>
				</PortalContentWrapper>
			</>
		);
	}
}

export default withAccountInfo(withThreeSkyeGlobal(StandingOrders));
