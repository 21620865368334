import React, { useState, useEffect } from 'react';
import { withThreeSkyeGlobal } from '@threeskye/global'
import { withAccountInfo } from '../../../../core-components/contexts/AccountsContext';
// import withReviewValuation from './withReviewValuation';
import Loading from '../../../../core-components/layouts/Loading';
import ReviewsLineChart from './ReviewsLineChart'
import { getYear, subDays, addDays } from 'date-fns'
import "./AccountReviews.scss";


const ReviewsPerformanceChart = (props) => {
    const { i18n, account, startDate, endDate, remote, toaster } = props;
    const i = i18n.get

    const [loadingInception, setLoadingInception] = useState(null);
    const [inceptionHistory, setInceptionHistory] = useState();

    useEffect(() => {
        setLoadingInception(true)
        // let url = `/modules/crm/accounts/${account.id}/performance-history?taxType=${!wealthTech ? "after_fees" : summaryNetGross}`;
        let url = `/modules/crm/accounts/${account.id}/performance-history?taxType=after_fees`;

        account && account.id &&
            remote.get(url)
                .then(data => {
                    if (data.connectionError) {
                        toaster.toast(data.connectionErrorMessage);
                        setLoadingInception(false)
                    } else {
                        setInceptionHistory(data.data);
                        setLoadingInception(false);
                    }
                });
    }, [account, remote, toaster])


    // Chart data
    let chartData = inceptionHistory ?
        inceptionHistory.map((datum) =>
            ({ date: new Date(datum.date).getTime(), value: datum.value })
        ) :
        null;

    if (startDate && endDate) {
        chartData = chartData && chartData.filter((datum) => (new Date(datum.date) > subDays(new Date(startDate), 1).getTime()) && (new Date(datum.date) < addDays(new Date(endDate), 1).getTime()));
        if (chartData && chartData.length === 0) {
            chartData = [{ date: new Date(endDate).getTime(), value: 0 }];
        }
    } else chartData = [{ date: new Date().getTime(), value: 0 }];

    // YAxis start at 0
    let startValue = chartData && chartData.length > 0 && chartData[0].value
    chartData = chartData && chartData.map((datum) =>
        ({ date: datum.date, value: datum.value - startValue }))

    // Months ticks
    let ticks = [];
    if (chartData && chartData.length > 0) {
        for (let i = 0; i < chartData.length; i++) {
            ticks.push(chartData[i].date);
        }
        ticks.push(chartData[chartData.length - 1].date)
    }

    // Years ticks
    const years = Array.from(new Set(ticks.map(t => getYear(new Date(t)))))
    if (years && years.length >= 5 && ticks && ticks.length === years.length + 1) {
        const lastDate = ticks[ticks.length - 1]
        const secondToLastDate = ticks[ticks.length - 2]
        if (getYear(new Date(lastDate)) === getYear(new Date(secondToLastDate))) {
            ticks.splice(-2, 1)
        }
    }

    // Areas
    let areas = chartData && [[chartData[0].date]];

    function pairDates(dates) {
        const pairedDates = [];
        for (let i = 0; i < dates.length; i += 2) {
          // If it's the last element and there's an odd number of elements, add it as a single-element array.
          if (i + 1 < dates.length) {
            pairedDates.push([dates[i], dates[i + 1]]);
          } else {
            pairedDates.push([dates[i]]);
          }
        }
        return pairedDates;
      }

    if (chartData && chartData.length > 0) {
        const dates = chartData.map(d => d.date)
        const allAreas = pairDates(dates)
        areas = allAreas
    }


    return (
        <div className="portfolio-graph">
            {
                loadingInception ? (
                    <Loading size={30} centered header={i("Loading")} />
                ) :
                    chartData ?
                        <div className='ts-reviews-theme'>
                            <ReviewsLineChart
                                chartData={chartData}
                                ticks={ticks}
                                yearColour="black"
                                performance={true}
                                fullWidth={true}
                                areas={areas}
                            />
                            {/* <div className='chart-leyenda leyendas'>
                                <div className='chart-leyenda'><div className='reviews-dot dot-2'></div><span className='chart-leyenda-span'>Contribution</span></div>
                                <div className='chart-leyenda'><div className='reviews-dot dot-1'></div><span className='chart-leyenda-span'>Valuation</span></div>
                            </div> */}
                        </div>
                        : ""
            }
        </div>
    )
}

export default withAccountInfo(withThreeSkyeGlobal(ReviewsPerformanceChart));